import React from "react";
import Markdown from 'react-markdown';
import styled from 'styled-components';

import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { PrivacyPolicyContent } from './content/privacy-policy';

const MarkdownWrapper = styled(Markdown)`
  p {
    margin-bottom: 1rem;
  }
`;

export const PrivacyPolicy = () => (
  <PageWrapper title="CareLoop Health - Privacy Policy">
      <MarkdownWrapper>{PrivacyPolicyContent}</MarkdownWrapper>
   </PageWrapper>
);
