/* eslint-disable */
//import React, { Component } from "react";
import React, {Component, PropTypes} from 'react';
import RichTextEditor from 'react-rte';

import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, FormGroup, Form, Label, Input, Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { isStudyStarted } from '../../helpers';
import { screensActions } from '../../actions';
import { screensService } from '../../services';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';

class ScreensEdit extends Component {
    constructor(props) {
        super(props);
        var pid = props.match.params.pid;
        var readOnly = isStudyStarted();

        this.state = {
            readOnly: readOnly,
            content: RichTextEditor.createEmptyValue(),
            alert: null,
            show: false,
            id: pid,
            data: [],
            isAdd: false,
            validation: {
                type: 'has-danger'
                //content: 'has-danger'
            },
            type: '',
            //content: '',
            enabled: '',
            title: ''
        };

        if (props.location.state && props.location.state.theScreen) {
            var p = props.location.state.theScreen;
            if (p && p !== null) {
                this.state.type = p.type;
                this.state.title = p.title;
                this.state.enabled = p.enabled;
                this.state.content = RichTextEditor.createValueFromString(p.content,'html');
                this.state.validation = {
                    type: ''
                    //content: ''
                }
            } else {
                this.props.history.goBack();
            }
        } else {
            this.state.isAdd = true;
        }
    }

    hideAlert() {
        this.setState({ alert: null });
    }

    hideAlert1() {
        this.setState({ alert: null });
        this.props.history.goBack();
    }

    saveScreen() {
        if (this.state.validation.type === "has-danger") {
            this.setState({
                alert: (
                    <SweetAlert
                        error
                        style={{ display: "block", marginTop: "100px" }}
                        title="Something when wrong!"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="danger"
                    >
                        Please review the errors and try again.
                  </SweetAlert>
                )
            });
        } else {
            var theScreen = {};

            if (this.state.isAdd !== true) {
                theScreen = this.props.location.state.theScreen;
            }

            theScreen.type = this.state.type;
            theScreen.title = this.state.title;
            theScreen.enabled = this.state.enabled;
            theScreen.content = this.state.content.toString('html');
        }

        if (this.state.isAdd !== true) {
            screensService.saveScreen(theScreen, theScreen.id).then(r => {
                this.setState({
                    alert: (
                        <SweetAlert
                            success
                            style={{ display: "block", marginTop: "100px" }}
                            title="Screen Saved"
                            onConfirm={() => this.hideAlert1()}
                            onCancel={() => this.hideAlert1()}
                            confirmBtnBsStyle="info"
                        >
                        </SweetAlert>
                    )
                });

            }
            );
        } else {
            screensService.addScreen(theScreen).then(r => {
                this.setState({
                    alert: (
                        <SweetAlert
                            success
                            style={{ display: "block", marginTop: "100px" }}
                            title="Screen Saved"
                            onConfirm={() => this.hideAlert1()}
                            onCancel={() => this.hideAlert1()}
                            confirmBtnBsStyle="info"
                        >
                        </SweetAlert>
                    )
                });
            }
            );
        }
    }

    onChange = (content) => {
        this.setState({content});
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it would be better to avoid generating a string on each change.
            this.props.onChange(
                content.toString('html')
            );
        }
    };

    render() {
        //const { items, participantsLoading } = this.props;
        var title = 'Create new screen';
        if (this.state.id > 0) {
            if (this.state.readOnly){
                title = `View screen ${this.state.title}`
            } else {
                title = `Edit screen ${this.state.title}`
            }
        }


        const contentValidationError = () => {
            if (this.state.validation.content === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Content should have more than 100 characters
                        </small>
                        </p>
                    </>
                );
            }
        }

        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed). 
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'], //, 'LINK_BUTTONS'
            INLINE_STYLE_BUTTONS: [
              {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
              {label: 'Italic', style: 'ITALIC'},
              {label: 'Underline', style: 'UNDERLINE'}
            ],
            BLOCK_TYPE_DROPDOWN: [
              {label: 'Normal', style: 'unstyled'},
              {label: 'Heading Large', style: 'header-one'},
              {label: 'Heading Medium', style: 'header-two'},
              {label: 'Heading Small', style: 'header-three'}
            ],
            BLOCK_TYPE_BUTTONS: [
              {label: 'UL', style: 'unordered-list-item'},
              {label: 'OL', style: 'ordered-list-item'}
            ]
        };

        var buttonSubmit = <Button className="btn-fill pull-right" color="success" type="submit" onClick={this.saveScreen.bind(this)}> Submit </Button>;

        if(isStudyStarted()){ 
            buttonSubmit = "";
        }

        var cannotDisable = this.state.readOnly || this.state.type == "PRIVACY";

        return (

            <>
                {this.state.alert}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <Card>
                                        <CardTitle tag="h4" className="pull-left" >{title}</CardTitle>
                                        {buttonSubmit}
                                        {"  "}
                                        <Button className="btn-fill pull-right" color="danger" type="submit"
                                            onClick={() => {
                                                this.props.history.goBack();
                                            }}>
                                            Cancel
                                        </Button>
                                    </Card>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FormGroup check className="mt-3">
                                                    <Label check>
                                                        <Input type="checkbox" name="enabled"
                                                            checked={this.state.enabled}
                                                            disabled = {cannotDisable} 
                                                            onChange={() => this.setState({ enabled: !this.state.enabled })} />
                                                        <span className="form-check-sign" /> 
                                                        Enabled
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <br />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                {contentValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.content}>
                                                    <RichTextEditor 
                                                        toolbarConfig={toolbarConfig}
                                                        value={this.state.content}
                                                        onChange={this.onChange}
                                                        readOnly = {this.state.readOnly}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>

                                <CardFooter className="pull-right">
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

function mapState(state) {
    const { items, screensLoading } = state.screens;
    return { items, screensLoading };
}

const actionCreators = {
    getAll: screensActions.getAll
};

const connectedScreensEdit = connect(mapState, actionCreators)(ScreensEdit);
export { connectedScreensEdit as ScreensEdit }; 