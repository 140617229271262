
import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  Nav,
  NavItem,
  Container,
  NavbarToggler
} from "reactstrap";

import iconpass from 'assets/img/passreset.png'
class AuthNavbar extends React.Component {
  state = {
    isOpen: false
  };
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  render() {
    return (
      <Navbar
        expand="lg"
        className={
          this.state.isOpen
            ? "bg-white navbar-absolute"
            : "navbar-transparent navbar-absolute"
        }
      >
        <Container fluid>
        <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <NavbarToggler onClick={this.toggle}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
          </div>
          <Collapse className="!block !visible" isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className={this.activeRoute("/auth/login-page")}>
                <Link to="/auth/login-page" className="nav-link flex flex-row gap-2 !text-xs items-center">
                  <i className="now-ui-icons users_circle-08" style={{ width: '21px', height: '21px', lineHeight: '21px', top: 0}} /> Login
                </Link>
              </NavItem>
              <NavItem className={this.activeRoute("/auth/password-help")}>
                <Link to="/auth/password-help" className="nav-link flex flex-row gap-2 !text-xs items-center">
                  <img src={iconpass} alt="pass recovery"  style={{ width: '21px', height: '21px' }}/> Password Help
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default AuthNavbar;
