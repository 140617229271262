import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

import { participantsActions } from '../../../actions';
import { CartesianGrid, Legend, LineChart, XAxis, YAxis, Line, ResponsiveContainer, Tooltip } from 'recharts';
import DateFilter from 'components/DateFilter/DateFilter';

const colors = [
  "#4B0082", // Indigo
  "#2E8B57", // Sea green
  "#FF8C00", // Dark orange
  "#8B0000", // Dark red
  "#4682B4", // Steel blue
  "#228B22", // Forest green
  "#FF4500", // Orange red
  "#DC143C", // Crimson
  "#FFA500", // Bold yellow-orange
  "#9932CC", // Dark orchid
  "#8A2BE2", // Blue violet
  "#B22222"  // Firebrick
];

const filterByDateRange = (array, startDate, endDate) => {
  return array.filter(item => {
    const itemDate = moment(item.datetime);
    return itemDate.isSameOrAfter(startDate) && itemDate.isSameOrBefore(endDate);
  });
};

const transformEncountersForLineChart = (responses) => {
  return responses
    .map(response => {
      const formattedDate = moment(response.datetime).format('DD/MM');
      const dataPoint = { name: formattedDate };
      const symptomSums = {};
      const symptomCounts = {};

      response.details.forEach(detail => {
        if (!symptomSums[detail.symptom]) {
          symptomSums[detail.symptom] = 0;
          symptomCounts[detail.symptom] = 0;
        }
        symptomSums[detail.symptom] += detail.answer;
        symptomCounts[detail.symptom] += 1;
      });

      Object.keys(symptomSums).forEach(symptom => {
        dataPoint[symptom] = (symptomSums[symptom] / symptomCounts[symptom]).toFixed(1);
      });

      return { ...dataPoint, datetime: response.datetime };
    })
    .sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
    .map(({ datetime, ...dataPoint }) => dataPoint);
};

const getUniqueProperties = (dataArray) => {
  const allKeys = dataArray.reduce((acc, obj) => {
    const keys = Object.keys(obj).filter(key => key !== 'name');
    return acc.concat(keys);
  }, []);

  const uniqueKeys = [...new Set(allKeys)];

  return uniqueKeys;
};

export const SymptomsGraphsTab = () => {
  const { pid: participantId } = useParams();
  const dispatch = useDispatch();

  const [lineChartData, setLineChartData] = useState([]);
  const [dataStartDate, setDataStartDate] = useState(null);
  const [dataEndDate, setDataEndDate] = useState(null);
  const [selectedSymptom, setSelectedSymptom] = useState(null);

  const {
    participantsLoading: storeLoading,
    summary: {
      encounters: storedEncounters = [],
    }
  } = useSelector((state) => state.participants);

  useEffect(() => {
    dispatch(participantsActions.getSummary(participantId));
    setDataStartDate(moment().subtract(1, 'week'));
    setDataEndDate(moment());
  }, [dispatch, participantId]);

  useEffect(() => {
    if (!storedEncounters.length || storeLoading) return;

    const filteredEncounters = filterByDateRange(storedEncounters, dataStartDate, dataEndDate);
    const chartData = transformEncountersForLineChart(filteredEncounters);

    setLineChartData(chartData);
  }, [storedEncounters, storeLoading, dataEndDate, dataStartDate]);

  const updateSelectedSymptom = (symptom) => {
    const newSymptom = selectedSymptom === symptom ? null : symptom;
    setSelectedSymptom(newSymptom);
  }

  const handleStartDateChange = (date) => {
    setDataStartDate(moment(date));
    setSelectedSymptom(null);
  }

  const handleEndDateChange = (date) => {
    setDataEndDate(moment(date));
    setSelectedSymptom(null);
  }

  const handleLegendClick = (data) => {
    updateSelectedSymptom(data.dataKey);
  }

  const renderTooltip = ({ payload }) => {
    if (!payload || !payload.length) return null;

    return (
      <div className="bg-white border border-gray-300 p-2">
        {payload.map((entry, index) => {
          const isHighlighted = selectedSymptom === entry.name;

          return (
            <p key={`item-${index}`} style={{ fontWeight: isHighlighted ? 'bold' : 'normal', color: entry.color }}>
              {entry.name}: {entry.value}
            </p>
          );
        })}
      </div>
    );
  };

  const renderChartLines = () => {
    if (!lineChartData.length || storeLoading) return null;

    const symptoms = getUniqueProperties(lineChartData);

    return symptoms.map((symptom, index) => {
      const isHighlighted = selectedSymptom === symptom;

      return (
        <Line
          onClick={() => updateSelectedSymptom(symptom)}
          type="monotone"
          dataKey={symptom}
          stroke={colors[index]}
          strokeWidth={isHighlighted ? 5 : 3}
          opacity={selectedSymptom && !isHighlighted ? 0.2 : 1}
          key={symptom}
        />
      );
    });
  }

  const renderChart = () => {
    if (!lineChartData.length) {
      return (
        <>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={[]}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
            </LineChart>
          </ResponsiveContainer>
        </>
      )
    }

    return (
      <>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={lineChartData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={renderTooltip} />
            <Legend onClick={handleLegendClick} /> 
            {renderChartLines()}
          </LineChart>
        </ResponsiveContainer>
      </>
    )
  }

  return (
    <div>
      <DateFilter
        setStartDate={handleStartDateChange}
        setEndDate={handleEndDateChange}
      />
      {renderChart()}
    </div>
  );
}
