
import { genericConstants } from '../constants';
 
const initialState = {   
    formsLoading:false, 
    error:""
} ;

export function forms(state = initialState, action) {
    switch (action.type) {
        case genericConstants.FORMS_REQUEST:
            return {
                formsLoading: true
            };

        case genericConstants.FORMS_SUCCESS:
            return { 
                formsLoading:false,
                forms: action.items,
                error:""
            };

        case genericConstants.FORMS_FAILURE:
            return {
                formsLoading:false,
                forms: [], 
                error:action.error
            }; 

        default:
            return state
    }
}