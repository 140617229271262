/* eslint-disable */
import React from "react";
import styled from 'styled-components';
import Markdown from 'react-markdown';

import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { TermsAndConditionsContent } from './content/terms-and-conditions';

const MarkdownWrapper = styled(Markdown)`
  p {
    margin-bottom: 1rem;
  }
`;

export const TermsnConditions = () => (
  <PageWrapper title="CareLoop Health - Terms &amp; Conditions">
      <MarkdownWrapper>{TermsAndConditionsContent}</MarkdownWrapper>
   </PageWrapper>
);
