import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import QRCode from "react-qr-code";
import styled from 'styled-components';
import { Button, Col, Row} from 'reactstrap';

import { participantsActions } from '../../actions';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { PatientBanner } from 'components/PatientBanner/PatientBanner';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const InstallSection = styled.section`
  margin-bottom: 1rem;

  h3 {
    margin-bottom: 5px;
  }

  span {
    background-color: #f0f0f0;
    margin: 3px;
    border: 1px solid black;
    padding: 0 6px;
  }
`;

export const ServiceUserMobileSetup = withRouter(({ history }) => {
  const { pid: participantId } = useParams();

  const [mobileCode, setMobileCode] = useState('1234567');

  const {
    summary
  } = useSelector((state) => state.participants);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(participantsActions.getSummary(participantId));
  }, [dispatch, participantId]);


  useEffect(() => {
    if (summary.participant) {
      setMobileCode(summary.participant.user.mobileCode);
    }
  }, [summary.participant]);

  const handleBack = () => {
    window.location.href = `/clintouch/admin/participants/${participantId}/summary`;
  }

  return (
    <>
      <PatientBanner id={participantId} participant={summary.participant} />
      <PageWrapper title={'Mobile setup details'} withPatientBanner>
        <Row>
          <Col xs={6} md={6}>
            <InstallSection>
              <h3>To start using the mobile app, scan the below QR code</h3>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "50%" }}
                value={mobileCode}
                viewBox={`0 0 256 256`}
              />
            </InstallSection>
          </Col>
          <Col xs={6} md={6}>
            <InstallSection>
              <h3>Or enter this mobile code manually</h3>
              {mobileCode.split('').map((char, index) => {
                return (
                  <span key={index} className="!text-4xl !font-bold">
                    {char}
                  </span>
                )
              })}
            </InstallSection>
          </Col>
        </Row>
        <div className="flex flex-row justify-between">
          <Button className="btn-fill bg-red-600" type="submit" onClick={handleBack}>
            Back
          </Button>
        </div>
      </PageWrapper>
    </>
  );
});
