
import { genericConstants } from '../constants';
 
const initialState = {  
    lookupsLoading:false,  
    error:"",
    formsbt: []
} ;

export function lookups(state = initialState, action) {
    switch (action.type) {
        case genericConstants.LOOKUPS_GET_REQUEST:
            return {
                lookupsLoading: true
            };

        case genericConstants.LOOKUPS_GET_SUCCESS_FORMS:
            return { 
                lookupsLoading:false,
                forms: action.forms,
                error:""
            };
        case genericConstants.LOOKUPS_GET_SUCCESS_FORMSBT:
            return { 
                lookupsLoading:false,
                formsbt: action.formsbt,
                error:""
            };
        case genericConstants.LOOKUPS_GET_SUCCESS_CONCEPTS:
            return { 
                lookupsLoading:false,
                concepts: action.concepts,
                error:""
            };

        case genericConstants.LOOKUPS_GET_FAILURE:
            return {
                lookupsLoading:false,
                concepts: [], 
                formsbt: [], 
                forms: [], 
                error:action.error
            }; 

        default:
            return state
    }
}