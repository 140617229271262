import { genericConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from '.';
import { history } from '../helpers';
import decode from 'jwt-decode';

export const authActions = {
    login,
    logout
};

function login(username, password, mfacode) {
    console.log("JLIZASO authActions login: ",username, password, mfacode);

    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password, mfacode)
            .then(
                userToken => {
                    //console.log("JLIZASO authActions userToken: ", userToken);
                    var fToken = decode(userToken);
                    console.log("JLIZASO Token: ",fToken, userToken);
                    userService.getCurrentUser(fToken.sub,userToken).then( 
                        cUser=>{
                            window.localStorage.setItem("login",cUser.login);
                            window.localStorage.setItem("role",cUser.role);
                            window.localStorage.setItem("id",cUser.id);
                            
                            //console.log("JLIZASO authActions getCurrentUser success: ", cUser);
                            dispatch(success(userToken));
                            history.push('/');
                        },
                        error => {
                            //console.log("JLIZASO authActions getCurrentUser error: ", error.toString());
                            dispatch(failure(error.toString()));
                            dispatch(alertActions.error(error.toString()));
                        }
                    );
                },
                error => {
                    //console.log("JLIZASO authActions error userToken: ", error.toString());

                    if(error.toString().includes("INVALID_CREDENTIALS") || error.toString().includes("USER_BLOCKED")){
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                    else if(error.toString().includes("MFA_CODE_REQUIRED")){
                        dispatch(requestMFA(username, password));
                    }
                    else{
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                }
            ).catch(err => {
                //console.log("JLIZASO authActions catched error: ",err);
                alertActions.error("bad-credentials");
                //dispatch(alertActions.error(err.toString()));
                dispatch(failure("bad-credentials"));
            });
    };

    function request(username) { return { type: genericConstants.LOGIN_REQUEST, username } }
    function requestMFA(username, password) { return { type: genericConstants.MFA_REQUESTED, username, password } }
    function success(userToken) { return { type: genericConstants.LOGIN_SUCCESS, userToken } }
    function failure(error) { return { type: genericConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: genericConstants.LOGOUT };
}