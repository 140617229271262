import { genericConstants } from '../constants';

let uToken = JSON.parse(localStorage.getItem('usertoken'));
let loggedIn = uToken ==null? false : uToken.length > 10;
  
const initialState = {  
    loggingIn:false,
    loggedIn: loggedIn,
    needMFA: false,
    userToken: uToken,
    error:""
} ;

//console.log("JLIZASO auth.reducer initialState: ",initialState);

export function authentication(state = initialState, action) {
    //console.log("JLIZASO auth.reducer authentication action: ",action);

    switch (action.type) {
        case genericConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                username: action.username, 
            };

        case genericConstants.MFA_REQUESTED:
            return {
                loggingIn: true,
                username: action.username, 
                password: action.password,
                loggedIn: false,
                needMFA: true,
            };

        case genericConstants.LOGIN_SUCCESS:
            return {
                loggingIn:false,
                loggedIn: true,
                needMFA: false,
                userToken: action.userToken,
                error:""
            };

        case genericConstants.LOGIN_FAILURE:
            return {
                loggingIn:false,
                loggedIn: false,
                userToken: null,
                needMFA: false,
                error: action.error
            };

        case genericConstants.LOGOUT:
            return {
                loggingIn:false,
                loggedIn: false,
                userToken: null,
                needMFA: false,
                error:""
            };

        default:
            return state
    }
}