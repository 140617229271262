
import { genericConstants } from '../constants';

const initialState = {
    participantsLoading: false, 
    summary: {},
    mobileCode: '',
    error: ""
};
 
export function participants(state = initialState, action) {
    switch (action.type) {
        case genericConstants.PARTICIPANTS_REQUEST:
            return {
                participantsLoading: true
            };

        case genericConstants.PARTICIPANTS_SUCCESS:
            return {
                participantsLoading: false,
                participants: action.items,
                summary:{},
                mobileCode: '',
                error: ""
            };

        case genericConstants.PARTICIPANTS_FAILURE:
            return {
                participantsLoading: false,
                participants: [],
                summary:{},
                mobileCode: '',
                error: action.error
            };

        case genericConstants.PARTICIPANTS_SUMMARY_REQUEST:
            return {
                participantsLoading: true,
                summary:{}
            };

        case genericConstants.PARTICIPANTS_SUMMARY_SUCCESS:
            return {
                participantsLoading: false,
                summary: action.dataObj,
                error: ""
            };

        case genericConstants.PARTICIPANTS_SUMMARY_FAILURE:
            return {
                participantsLoading: false,
                summary:{},
                error: action.error
            };

        case genericConstants.PARTICIPANT_REQUEST:
            return {
                participantsLoading: true,
                summary:{}
            };

        case genericConstants.PARTICIPANT_SUCCESS:
            return {
                participantsLoading: false,
                summary: action.dataObj,
                error: ""
            };

        case genericConstants.PARTICIPANT_FAILURE:
            return {
                participantsLoading: false,
                summary:{},
                error: action.error
            };

        case genericConstants.PARTICIPANT_MOBILE_CODE_REQUEST:
            return {
                ...state,
                particpantsLoading: true,
                mobileCode: ''
            };

        case genericConstants.PARTICIPANT_MOBILE_CODE_SUCCESS:
            return {
                ...state,
                particpantsLoading: false,
                mobileCode: action.dataObj
            };

        case genericConstants.PARTICIPANT_MOBILE_CODE_FAILURE:
            return {
                ...state,
                particpantsLoading: false,
                error: action.error
            };

        default:
            return state
    }
}