
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import routes from "routes.js";
import styled from "styled-components";

var ps;

const Background = styled.div`
  background: rgb(8, 132, 143);
  background: url(https://careloop.health/wp-content/themes/careloop/img/Home-header-massive.png) no-repeat bottom left, linear-gradient(90deg, rgba(8, 132, 143, 1) 0%, rgba(79, 32, 86, 1) 100%);
  background-size: 100% auto;
  background-size: 105%;
  overflow: hidden;
`;

class Auth extends React.Component {
  state = {
    filterColor: "blue"
  };
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleColorClick = color => {
    this.setState({ filterColor: color });
  };
  render() {
    return (
      <>
        <AuthNavbar {...this.props} />
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <Background
            className="full-page section-image"
          >
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="/auth" to="/auth/login-page" />
            </Switch>
            <Footer fluid />
          </Background>
        </div>
      </>
    );
  }
}

export default Auth;
