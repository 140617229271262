/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { CardTitle, Row, Col, FormGroup, Form, Input, Label, Button, InputGroupAddon, InputGroupText, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { isStudyStarted, updateInstallStatus } from '../../helpers';
import { providersActions, lookupActions, participantsActions } from '../../actions';
import { participantsService } from '../../services/data.service';
import { proxysService } from '../../services/data.service';
import { useParams, withRouter } from "react-router-dom/cjs/react-router-dom";

const InputWrapper = ({ className, showHint, hint, valid, validationMessage, children }) => {
  return (
    <div className={className}>
      {showHint && <p className="text-gray-800 text-xs mb-2">{hint}</p>}
      {children}
      {valid && <p className="text-red-700 text-xs">{validationMessage || 'This field is required'}</p>}
    </div>
  );
};

export const ParticipantsEdit = withRouter((props) => {
  const dispatch = useDispatch();
  const { pid: participantId } = useParams();

  const [providers, setProviders] = useState([]);
  const [forms, setForms] = useState([]);
  const [delusionForms, setDelusionForms] = useState([]);
  const [selfHarmForms, setSelfHarmForms] = useState([]);
  const [sideEffectForms, setSideEffectForms] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userId, setUserId] = useState('');
  const [isNew, setIsNew] = useState(false);

  const { providers: stateProviders } = useSelector(state => state.providers);
  const { forms: stateLookupForms } = useSelector(state => state.lookups);
  const { summary: participant, mobileCode: stateMobileCode } = useSelector(state => state.participants);

  useEffect(() => {
    if (stateMobileCode) {
      setState(prevState => ({ ...prevState, mobileCode: stateMobileCode }));
    }
  }, [stateMobileCode])

  useEffect(() => {
    setIsNew(!participant.id);

    if (participant.id) {
      const dobFormatted = participant.dob ? moment(participant.dob).format('YYYY-MM-DD') : '';

      setState(prevState => ({
        ...prevState,
        district: participant.user.login,
        firstName: participant.user.firstName || undefined,
        surname: participant.user.lastName || undefined,
        mobileNo: participant.user.mobile || undefined,
        email: participant.user.email || undefined,
        dob: dobFormatted,
        mobileCode: participant.user.mobileCode,
        mobileCodeVerf: participant.user.mobileCode,
        mappstarted: participant.alertConfig ? participant.alertConfig.mobileAppStarted : false,
        validation: {
          district: '',
          provider: participant.provider.id ? 'has-success' : 'has-danger',
          firstName: participant.user.firstName ? 'has-success' : 'has-danger',
          surname: participant.user.lastName ? 'has-success' : 'has-danger',
          mobileNo: participant.user.mobile ? 'has-success' : 'has-danger',
          dob: participant.dob ? 'has-success' : 'has-danger',
          mobileCode: participant.user.mobileCode ? 'has-success' : 'has-danger',
          email: isValidEmail(participant.user.email) ? 'has-success' : 'has-danger',
          samePrimaryAndSecondaryDelusion: ''
        },
        provider: participant.provider ? { value: participant.provider.id, label: participant.provider.user.login } : '',
        pdelusion: participant.alertConfig && participant.alertConfig.primaryDelusionForm ? { value: participant.alertConfig.primaryDelusionForm.id, label: participant.alertConfig.primaryDelusionForm.name } : '',
        sdelusion: participant.alertConfig && participant.alertConfig.secondaryDelusionForm ? { value: participant.alertConfig.secondaryDelusionForm.id, label: participant.alertConfig.secondaryDelusionForm.name } : '',
        sideeffects: participant.alertConfig && participant.alertConfig.sideEffectsForm ? { value: participant.alertConfig.sideEffectsForm.id, label: participant.alertConfig.sideEffectsForm.name } : '',
        includeSelfHarmQuestionnaire: participant.alertConfig && participant.alertConfig.alertForms && participant.alertConfig.alertForms.some(form => form.type === "SELF_HARM"),
        personalQuestionOne: participant.alertConfig.personalForm?.questions[0]?.text,
        personalQuestionTwo: participant.alertConfig.personalForm?.questions[1]?.text,
        personalQuestionThree: participant.alertConfig.personalForm?.questions[2]?.text
      }));
    }
  }, [participant]);

  useEffect(() => {
    setIsAdmin(window.localStorage.getItem('role') === 'ROLE_ADMIN');
    setUserId(window.localStorage.getItem('id'));
    dispatch(lookupActions.getForms());
  }, []);

  useEffect(() => {
    if (participantId > 0) dispatch(participantsActions.getParticipant(participantId));
    if (participantId === '0') dispatch(participantsActions.getMobileCode());
  }, [participantId]);

  useEffect(() => {
    if (!stateLookupForms) return;

    const delusionForms = stateLookupForms?.filter((form) => form.type === 'DELUSIONS').map((form) => ({ value: form.id, label: form.name }));
    const selfHarmForms = stateLookupForms?.filter((form) => form.type === 'SELF_HARM').map((form) => ({ value: form.id, label: form.name }));
    const sideEffectForms = stateLookupForms?.filter((form) => form.type === 'SIDE_EFFECT').map((form) => ({ value: form.id, label: form.name }));

    setForms(stateLookupForms);
    setDelusionForms(delusionForms);
    setSelfHarmForms(selfHarmForms);
    setSideEffectForms(sideEffectForms);
  }, [stateLookupForms])

  useEffect(() => {
    if (stateProviders?.length >= 1) {
      setProviders(stateProviders.map((p) => ({ value: p.id, label: p.user.login })));
    }
  }, [stateProviders]);

  useEffect(() => {
    if (providers.length === 1) {
      setState(prevState => ({
        ...prevState,
        provider: providers[0],
        validation: {
          ...prevState.validation,
          provider: 'has-success'
        }
      }));
    }
  }, [providers]);

  useEffect(() => {
    if (!isAdmin && userId) dispatch(providersActions.getByUserId(userId));

    if (isAdmin) dispatch(providersActions.getAll());
  }, [isAdmin, userId]);

  const [state, setState] = useState({
    alert: null,
    show: false,
    lforms: [],
    seforms: [],
    sformsfull: [],
    lformsfull: [],
    seformsfull: [],
    id: participantId,
    data: [],
    district: '',
    firstName: '',
    surname: '',
    email: '',
    mobileNo: '',
    dob: '',
    password: '',
    passwordVerf: '',
    mobileCode: '',
    showMobileCode: false,
    mobileCodeVerf: '',
    mappstarted: false,
    pdelusion: '',
    sdelusion: '',
    sideeffects: '',
    includeSelfHarmQuestionnaire: false,
    personalQuestionOne: '',
    personalQuestionTwo: '',
    personalQuestionThree: '',
    provider: '',
    providers: [],
    validation: {
      district: 'has-danger',
      provider: 'has-danger',
      firstName: 'has-danger',
      surname: 'has-danger',
      mobileNo: 'has-danger',
      dob: 'has-danger',
      mobileCode: 'has-success',
      email: 'has-success',
      samePrimaryAndSecondaryDelusion: ''
    }
  });

  const {
    alert, district, firstName, surname, email, mobileNo, dob, mobileCode, showMobileCode, includeSelfHarmQuestionnaire, personalQuestionOne, personalQuestionTwo, personalQuestionThree,
    pdelusion, sdelusion, sideeffects, provider, validation, mappstarted
  } = state;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
      validation: {
        ...prevState.validation,
        [name]: validateField(name, value)
      }
    }));
  };

  const isValidDate = (date) => {
    return !!moment(date, 'YYYY-MM-DD', true).isValid();
  }

  const isValidEmail = (email) => {
    if (!email) return true;

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return regex.test(email);
  }

  const validateField = (name, value) => {
    switch (name) {
      case 'district':
        return value.length >= 6 ? 'has-success' : 'has-danger';
      case 'firstName':
      case 'surname':
      case 'mobileNo':
        return !value ? 'has-danger' : 'has-success';
      case 'dob':
        return !isValidDate(value) || !value ? 'has-danger' : 'has-success';
      case 'mobileCode':
        return value.length === 7 ? 'has-success' : 'has-danger';
      case 'email':
        return isValidEmail(value) ? 'has-success' : 'has-danger'
      default:
        return validation[name];
    }
  };

  const handleProviderChange = (value) => {
    setState(prevState => ({
      ...prevState,
      provider: value,
      validation: {
        ...prevState.validation,
        provider: 'has-success'
      }
    }));
  };

  const handlePDelusionChange = (value) => {
    let delusionValidation = 'has-success';
    if (state.sdelusion && state.sdelusion.value === value.value) {
      delusionValidation = 'has-danger';
    }
    setState(prevState => ({
      ...prevState,
      pdelusion: value,
      validation: {
        ...prevState.validation,
        samePrimaryAndSecondaryDelusion: delusionValidation
      }
    }));
  };

  const handleSDelusionChange = (value) => {
    let delusionValidation = 'has-success';
    if (state.pdelusion && state.pdelusion.value === value.value) {
      delusionValidation = 'has-danger';
    }
    setState(prevState => ({
      ...prevState,
      sdelusion: value,
      validation: {
        ...prevState.validation,
        samePrimaryAndSecondaryDelusion: delusionValidation
      }
    }));
  };

  const handleSideEffectsChange = (value) => {
    setState(prevState => ({ ...prevState, sideeffects: value }));
  };

  const formValid = () => {
    return !Object.keys(validation).some(key => validation[key] === 'has-danger');
  };

  const insertPersonalQuestion = (question) => {
    return {
      "text": question,
      "answerValues": 7,
      "type": "PERSONAL",
      "branchType": "NO_BRANCH",
      "mobileGraphingCategory": "NONE",
      "leftLabel": "Not at all",
      "rightLabel": "Very much",
      "reverseResult": 0,
      "concept": {
        "id": "9ef71e50-ba42-11e3-a5e2-0800200c9a66"
      }
    }
  }

  const saveParticipant = () => {
    if (!formValid()) {
      setState(prevState => ({
        ...prevState,
        alert: (
          <SweetAlert
            error
            style={{ display: "block", marginTop: "100px" }}
            title="Something went wrong!"
            onConfirm={hideAlert}
            onCancel={hideAlert}
            confirmBtnBsStyle="danger"
          >
            Please review the errors tagged in red and try again.
          </SweetAlert>
        )
      }));
    } else {
      let theParticipant = {};

      if (!isNew) {
        theParticipant = {
          ...participant,
          alertConfig: {
            useDefaultBaseline: true,
            conceptWeights: null,
            primaryDelusionForm: null,
            secondaryDelusionForm: null,
            sideEffectsForm: null,
            alertForms: [],
            personalForm: {
              name: `PF: ${district}`,
              description: `PF: ${district}`,
              type: "PERSONAL",
              questions: []
            }
          }
        };
      } else {
        theParticipant = {
          user: {},
          provider: {},
          alertConfig: {
            useDefaultBaseline: true,
            conceptWeights: null,
            primaryDelusionForm: null,
            secondaryDelusionForm: null,
            sideEffectsForm: null,
            alertForms: [],
            personalForm: {
              name: `PF: ${district}`,
              description: `PF: ${district}`,
              type: "PERSONAL",
              questions: []
            }
          }
        };
      }

      theParticipant = {
        ...theParticipant,
        user: {
          ...theParticipant.user,
          password: mobileCode,
          login: district,
          mobileCode,
          role: "ROLE_PARTICIPANT",
          firstName,
          lastName: surname,
          mobile: mobileNo,
          email
        },
        provider: {
          ...theParticipant.provider,
          id: provider.value
        },
        dob
      };

      const currentURL = window.location.href;
      const pathname = window.location.pathname;
      const server = currentURL.replace(pathname, '');

      if (mobileCode !== state.mobileCodeVerf) {
        const proxyData = {
          mobileCode: theParticipant.user.mobileCode,
          user: theParticipant.user.login,
          server,
          dateCreated: null,
          dateConnected: null
        };
        proxysService.addUserProxy(proxyData);
      }

      if (pdelusion.value && pdelusion.value.length > 0) {
        theParticipant.alertConfig.primaryDelusionForm = forms.find((f) => f.id === pdelusion.value);
      }

      if (sdelusion.value && sdelusion.value.length > 0) {
        theParticipant.alertConfig.secondaryDelusionForm = forms.find((f) => f.id === sdelusion.value);
      }

      if (sideeffects.value && sideeffects.value.length > 0) {
        theParticipant.alertConfig.sideEffectsForm = forms.find((f) => f.id === sideeffects.value);
      }

      if (includeSelfHarmQuestionnaire && selfHarmForms) {
        for (let i = 0; i < selfHarmForms.length; i++) {
          const selfHarmForm = selfHarmForms[i];
          const form = forms.find((f) => f.id === selfHarmForm.value);

          if (!theParticipant.alertConfig.alertForms.some(f => f.id === form.id)) {
            theParticipant.alertConfig.alertForms.push(form);
          }
        }
      } else {
        theParticipant.alertConfig.alertForms = theParticipant.alertConfig.alertForms.filter(f => f.type !== "SELF_HARM");
      }

      if (personalQuestionOne) {
        theParticipant.alertConfig.personalForm.questions.push(
          insertPersonalQuestion(personalQuestionOne)
        )
      }

      if (personalQuestionTwo) {
        theParticipant.alertConfig.personalForm.questions.push(
          insertPersonalQuestion(personalQuestionTwo)
        )
      }

      if (personalQuestionThree) {
        theParticipant.alertConfig.personalForm.questions.push(
          insertPersonalQuestion(personalQuestionThree)
        )
      }

      const saveOrAddParticipant = isNew
        ? participantsService.addParticipant(theParticipant)
        : participantsService.saveParticipant(theParticipant, theParticipant.id);

      saveOrAddParticipant.then(r => {
        if (r) {
          if (isStudyStarted()) {
            updateInstallStatus();
          }
          setState(prevState => ({
            ...prevState,
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "100px" }}
                title="Participant Saved"
                onConfirm={hideAlert1}
                onCancel={hideAlert1}
                confirmBtnBsStyle="info"
              />
            )
          }));

          if (isNew) {
            window.location.href = '/clintouch/admin/participants';
          } else {
            window.location.replace(`/clintouch/admin/participants/${r.id}/setup`);
          }

        } else {
          setState(prevState => ({
            ...prevState,
            alert: (
              <SweetAlert
                error
                style={{ display: "block", marginTop: "100px" }}
                title="Something when wrong!"
                onConfirm={hideAlert}
                onCancel={hideAlert}
                confirmBtnBsStyle="danger"
              >
                There was an error saving the Service User. Check if it doesn't already exist.
              </SweetAlert>
            )
          }));
        }
      });
    }
  };

  const hideAlert = () => {
    setState(prevState => ({ ...prevState, alert: null }));
  };

  const hideAlert1 = () => {
    setState(prevState => ({ ...prevState, alert: null }));
    props.history.goBack();
  };

  const ShowMobileInstallationModalButton = () => {
    if (state.id === '0') return null;

    const handleOnClick = () => {
      window.location.replace(`/clintouch/admin/participants/${state.id}/setup`);
    }

    return (
      <Button className="m-0" color="primary" onClick={handleOnClick}>
        Mobile Installation Details
      </Button>
    )
  };

  return (
    <>
      {alert}
      <PageWrapper title={state.id > 0 ? `Edit service user ${district}` : 'Create new service user'} ActionButton={ShowMobileInstallationModalButton}>
        <Form action="#" method="#">
          <Row>
            <Col xs={12} md={6}>
              <div className="flex flex-col gap-3">
                <CardTitle className="text-lg" tag="h5">Demographics</CardTitle>

                <InputWrapper
                  showHint={true}
                  hint="The user Id provides the link to the patient in your patient record system. Please enter it with care."
                  valid={validation.district === "has-danger"}
                  validationMessage="User Id must be unique and at least 6 characters">
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="district">User Id</label>
                  <FormGroup className={"has-label " + validation.district}>
                    <Input id="district" type="text" name="district"
                      disabled={mappstarted}
                      value={district}
                      onChange={handleChange} />
                  </FormGroup>
                </InputWrapper>

                <InputWrapper valid={validation.firstName === 'has-danger'}>
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="firstName">Firstname</label>
                  <FormGroup className={"has-label " + validation.firstName}>
                    <Input id="firstName" type="text" name="firstName" value={firstName || ''} onChange={handleChange} />
                  </FormGroup>
                </InputWrapper>

                <InputWrapper valid={validation.surname === 'has-danger'}>
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="surname">Surname</label>
                  <FormGroup className={"has-label " + validation.surname}>
                    <Input id="surname" type="text" name="surname" value={surname || ''} onChange={handleChange} />
                  </FormGroup>
                </InputWrapper>

                <InputWrapper valid={email && validation.email === 'has-danger'} validationMessage="Please enter a valid email">
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="email">Email (optional)</label>
                  <FormGroup>
                    <Input id="email" type="email" name="email" value={email || ''} onChange={handleChange} />
                  </FormGroup>
                </InputWrapper>

                <InputWrapper valid={validation.mobileNo === 'has-danger'}>
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="mobileNo">Mobile No.</label>
                  <FormGroup className={"has-label " + validation.mobileNo}>
                    <Input className="text-left" id="mobileNo" type="number" name="mobileNo" value={mobileNo || ''} onChange={handleChange} />
                  </FormGroup>
                </InputWrapper>

                <InputWrapper
                  valid={validation.dob === 'has-danger'}
                  validationMessage={!isValidDate(dob) && dob ? 'Please enter a valid date' : 'This field is required'}>
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="dob">DOB</label>
                  <FormGroup className={"has-label " + validation.dob}>
                    <Input id="dob" type="date" name="dob" value={dob} onChange={handleChange} />
                  </FormGroup>
                </InputWrapper>

                <CardTitle className="text-lg">Access Details</CardTitle>

                <InputWrapper
                  valid={validation.provider === "has-danger"}
                  validationMessage="Please select a provider">
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="provider">Provider</label>
                  <FormGroup className={"has-label " + validation.provider}>
                    <Select
                      inputId="provider"
                      isDisabled={!isAdmin}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="Select a provider"
                      name="provider"
                      value={provider}
                      options={providers}
                      onChange={handleProviderChange} />
                  </FormGroup>
                </InputWrapper>

                <InputWrapper
                  valid={validation.mobileCode === "has-danger"}
                  validationMessage="Please enter a valid mobile code."
                  className="hidden">
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="mobileCode">Mobile Code</label>
                  <FormGroup className={"has-label after:content-['\ea53'] after:right-[4rem] " + validation.mobileCode}>
                    <InputGroup>
                      <Input
                        id="mobileCode"
                        type={showMobileCode ? "text" : "password"}
                        name="mobileCode"
                        disabled={true}
                        value={mobileCode}
                        onChange={handleChange}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="!px-4 !rounded-r-[30px]" onClick={() => setState(prevState => ({ ...prevState, showMobileCode: !showMobileCode }))}>
                          <i className={showMobileCode ? "fa fa-eye-slash" : "fa fa-eye"} />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </InputWrapper>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="flex flex-col gap-3">
                <CardTitle className="text-lg" tag="h5">PROMs / Questionnaires</CardTitle>

                <p className="category text-gray-800 text-xs mb-2">
                  Delusion cannot be altered after user has started using the app
                </p>

                <InputWrapper>
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="pdelusion">Delusion 1</label>
                  <FormGroup className={"has-label " + validation.samePrimaryAndSecondaryDelusion}>
                    <Select
                      inputId="pdelusion"
                      isDisabled={mappstarted}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="None"
                      name="pdelusion"
                      value={pdelusion}
                      options={delusionForms}
                      onChange={handlePDelusionChange}
                    />
                  </FormGroup>
                </InputWrapper>

                <InputWrapper
                  valid={validation.samePrimaryAndSecondaryDelusion === "has-danger"}
                  validationMessage="The Primary and Secondary delusion questionnaires cannot be the same.">
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="sdelusion">Delusion 2</label>
                  <FormGroup className={"has-label " + validation.samePrimaryAndSecondaryDelusion}>
                    <Select
                      inputId="sdelusion"
                      isDisabled={mappstarted}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="None"
                      name="sdelusion"
                      value={sdelusion}
                      options={delusionForms}
                      onChange={handleSDelusionChange}
                    />
                  </FormGroup>
                </InputWrapper>

                <CardTitle className="text-base" tag="h5">Personalised Questionnaire</CardTitle>

                <InputWrapper>
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="personalQuestionOne">Question 1</label>
                  <FormGroup className={"has-label " + validation.personalQuestionOne}>
                    <Input id="personalQuestionOne" type="text" name="personalQuestionOne"
                      disabled={mappstarted}
                      value={personalQuestionOne}
                      onChange={handleChange} />
                  </FormGroup>
                </InputWrapper>

                <InputWrapper>
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="personalQuestionTwo">Question 2</label>
                  <FormGroup className={"has-label " + validation.personalQuestionTwo}>
                    <Input id="personalQuestionTwo" type="text" name="personalQuestionTwo"
                      disabled={mappstarted}
                      value={personalQuestionTwo}
                      onChange={handleChange} />
                  </FormGroup>
                </InputWrapper>

                <InputWrapper>
                  <label className="!text-sm !mb-2 !text-stone-700" htmlFor="personalQuestionThree">Question 3</label>
                  <FormGroup className={"has-label " + validation.personalQuestionThree}>
                    <Input id="personalQuestionThree" type="text" name="personalQuestionThree"
                      disabled={mappstarted}
                      value={personalQuestionThree}
                      onChange={handleChange} />
                  </FormGroup>
                </InputWrapper>

                {/* Section visually hidden temporarily */}
                
                {/* <CardTitle className="text-base" tag="h5">Self Harm Questionnaire</CardTitle>

                <div>
                  <p className="category text-gray-800 text-xs !mb-3">
                    The Self Harm Questionnaire cannot be altered after user has started using the app
                  </p>

                  <FormGroup className="p-0 mt-3" check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="includeSelfHarmQuestionnaire"
                        disabled={mappstarted}
                        checked={includeSelfHarmQuestionnaire}
                        onChange={() => setState(prevState => ({ ...prevState, includeSelfHarmQuestionnaire: !includeSelfHarmQuestionnaire }))} />
                      <span className="form-check-sign" />
                      <span className="text-gray-800">Include Self Harm questionnaire for this user?</span>
                    </Label>
                  </FormGroup>
                </div> 
                
                <div>
                  <p className="category text-gray-800 text-xs !mb-3">
                    The Side Effects Questionnaire cannot be altered after user has started using the app
                  </p>

                  <InputWrapper>
                    <label className="!text-sm !mb-2 !text-stone-700" htmlFor="sideeffects">Side Effects</label>
                    <FormGroup>
                      <Select
                        isDisabled={mappstarted}
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="None"
                        inputId="sideeffects"
                        name="sideeffects"
                        value={sideeffects}
                        options={sideEffectForms}
                        onChange={handleSideEffectsChange}
                      />
                    </FormGroup>
                  </InputWrapper>
                </div> */}
              </div>
            </Col>
          </Row>
        </Form>
        <div className="flex flex-row justify-between">
          <Button className="btn-fill bg-red-600" type="submit" onClick={() => props.history.goBack()}>
            Cancel
          </Button>
          <Button className="btn-fill bg-green-700" type="submit" onClick={saveParticipant}>
            Submit
          </Button>
        </div>
      </PageWrapper>
    </>
  );
});
