export const QuestionnaireConcepts = Object.freeze({
  precipitants: {
    code: 'P',
    name: 'Precipitants',
    value: 'precipitants',
  },
  hope: {
    code: 'H',
    name: 'Hope',
    value: 'hope',
  },
  mood: {
    code: 'M',
    name: 'Mood',
    value: 'mood',
  },
  anxiety: {
    code: 'A',
    name: 'Anxiety',
    value: 'anxiety',
  },
  self: {
    code: 'S',
    name: 'Self',
    value: 'self',
  },
  others: {
    code: 'O',
    name: 'Others',
    value: 'others',
  },
  coping: {
    code: 'C',
    name: 'Coping',
    value: 'coping',
  },
  fearOfRecurrence: {
    code: 'FoR',
    name: 'Fear of recurrence',
    value: 'fearOfRecurrence',
  },
  unusualVoices: {
    code: 'UV',
    name: 'Unusual voices',
    value: 'unusualVoices',
  },
  seeingThings: {
    code: 'ST',
    name: 'Seeing things',
    value: 'seeingThings',
  },
  activityAndActivation: {
    code: 'AA',
    name: 'Activity and Activation',
    value: 'activityAndActivation',
  },
  feelingThreatened: {
    code: 'FT',
    name: 'Feeling threatened',
    value: 'feelingThreatened',
  },
  personalised: {
    code: 'PQ',
    name: 'Personalised',
    value: 'personalised',
  },
  delusions: {
    code: 'PD',
    name: 'Delusions',
    value: 'delusions',
  },
});
