import React from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import { ChartsWrapper } from './ChartsWrapper';
import { styled } from 'styled-components';

const COLORS = ['#00C49F', '#0088FE', '#D70040'];

const EngagementText = styled.h5`
  font-size: 14px;
  text-align: center;
`;

const TotalText = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 10px 0;
`;

const SkeletonPieChart = () => (
  <ResponsiveContainer width="100%" height={200}>
    <PieChart>
      <Legend />
      <Pie
        data={[{ value: 1, name: 'Completed' }, { value: 1, name: 'Partial' }, { value: 1, name: 'Missing' }]}
        innerRadius={60}
        outerRadius={80}
        startAngle={180}
        endAngle={0}
        fill="#8884d8"
        paddingAngle={4}
        dataKey="value"
        label={false}
        labelLine={false}
        cy="80%">
        <Cell key={`Completed`} fill="#ccc" />
        <Cell key={`Partial`} fill="#ccc" />
        <Cell key={`Missing`} fill="#ccc" />
      </Pie>
    </PieChart>
  </ResponsiveContainer>
);

export const EncountersPieChart = ({ completed = 0, missed = 0, partial = 0, title }) => {
  const data = [
    { name: 'Completed', value: completed },
    { name: 'Partial', value: partial },
    { name: 'Missed', value: missed },
  ];

  const total = (completed + missed + partial);

  if (total === 0) {
    return (
      <ChartsWrapper title={title}>
        <SkeletonPieChart />
        <hr />
        <div>
          <EngagementText className="mt-[20px]">No data available</EngagementText>
        </div>
      </ChartsWrapper>
    );
  }

  const average = (partial * 0.5) + (completed * 1);
  const engagementPercentage = (average / total) * 100;
  const engagementText = `${engagementPercentage.toFixed(2)}%`;

  return (
    <ChartsWrapper title={title}>
      <ResponsiveContainer width={'100%'} height={200}>
        <PieChart>
          <Legend />
          <Pie
            data={data}
            innerRadius={60}
            outerRadius={80}
            startAngle={180}
            endAngle={0}
            fill="#8884d8"
            paddingAngle={4}
            dataKey="value"
            label
            labelLine={false}
            cy="80%"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div>
        <TotalText>Total: {total}</TotalText>
      </div>
      <hr/>
      <div>
        <EngagementText className='mt-[20px]'>Enagement: {engagementText}</EngagementText>
      </div>
    </ChartsWrapper>
  );
};
