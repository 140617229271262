import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { overviewStatsActions } from '../../../actions';
import { EncountersPieChart } from 'views/Stats/EncountersPieChart';

export const ServiceGraphsInsightRow = () => {
  const [state, setState] = useState({
    totalNoOfParticipantsWithEncounters: '',
    totalNoOfParticipants: '',
    totalNoOfEncounters: '',
    totalNoOfPartialEncounters: '',
    totalNoOfCompleteEncounters: '',
    totalNoOfMissingEncounters: '',

    lastWeekPartial: '',
    lastWeekComplete: '',
    lastWeekMissing: '',
    lastMonthPartial: '',
    lastMonthComplete: '',
    lastMonthMissing: '',

    amountUsersCompleteWeek: '',
    amountUsersMissingWeek: '',
    amountUsersComplete3Day: '',
    amountUsersMissing3Day: '',
    lastEncounter: '',
    data: [],
  });

  const dispatch = useDispatch();
  const { overviewStats, overviewStatsLoading } = useSelector((state) => state.overviewStats);

  useEffect(() => {
    dispatch(overviewStatsActions.getAll())
      .then((response) => {
        const stats = response;
        setState((prevState) => ({
          ...prevState,
          ...stats,
        }));
      });
  }, [dispatch]);

  useEffect(() => {
    if (overviewStats) {
      setState((prevState) => ({
        ...prevState,
        ...overviewStats,
        lastEncounter: overviewStats.lastEncounter
          ? new Date(overviewStats.lastEncounter).toLocaleDateString('en-UK', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })
          : '',
      }));
    }
  }, [overviewStats]);

  if (overviewStatsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Row>
      <Col xs="12" md="3">
        <EncountersPieChart
          completed={state.lastWeekComplete}
          partial={state.lastWeekPartial}
          missed={state.lastWeekMissing}
          title="Last weeks encounters"
        />
      </Col>
      <Col xs="12" md="3">
        <EncountersPieChart
          completed={state.lastMonthComplete}
          partial={state.lastMonthPartial}
          missed={state.lastMonthMissing}
          title="Last months encounters"
        />
      </Col>
      <Col xs="12" md="3">
        <EncountersPieChart
          completed={state.totalNoOfCompleteEncounters}
          partial={state.totalNoOfPartialEncounters}
          missed={state.totalNoOfMissingEncounters}
          title="All encounters"
        />
      </Col>
      <Col xs={12} md={3}>
        <EncountersPieChart
          completed={state.amountUsersCompleteWeek}
          missed={state.amountUsersMissingWeek}
          title="Last week users completion"
        />
      </Col>
    </Row>
  )
};
