
import { genericConstants } from '../constants';

const initialState = {
  alertsLoading: false,
  error: ''
};

export function alerts(state = initialState, action) {
  switch (action.type) {
    case genericConstants.PARTICIPANTS_ALERTS_REQUEST:
      return {
        alertsLoading: true
      };

    case genericConstants.PARTICIPANTS_ALERTS_SUCCESS:
      return {
        alertsLoading: false,
        alerts: action.items,
        error: ''
      };

    case genericConstants.PARTICIPANTS_ALERTS_FAILURE:
      return {
        alertsLoading: false,
        alerts: [],
        error: action.error
      };

    default:
      return state
  }
}
