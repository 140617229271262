import moment from 'moment';
import { colorUtility } from '.';


export var dataTransService = {
    getAllConcepts,
    getAllQuestions,
    getMaxMinAnswerValues,
    getEncountersDatesRange,
    getChartLabels,
    getQuestionsPlotData,
    getConceptsPlotData,
    getSimpleLabels,
    getXAxisTicks,
};


function getMaxMinAnswerValues(questions) {

    var max = 0;
    var min = 1000;
    for (var question of questions) {
        //console.log("question: ", question);

        var filteredAnswers = question.answers.filter(Boolean);
        
        //console.log("filteredAnswers: ", filteredAnswers);

        if(filteredAnswers.length>0){ // question.answers
            for (var answer of filteredAnswers) { // question.answers
                if(answer.value>max){
                    max = answer.value
                }
                if(answer.value<min){
                    min = answer.value
                }
            }
        } else {
            if(question.answerValues>max){
                max = question.answerValues
            }
            min = 1
        }
    }

    //console.log("getMaxMinAnswerValues: ", questions);
    //console.log("getMaxMinAnswerValues: ", min, max);

    if(max===0){ max = 10;}
    if(min===1000){min = 0;}

    return { 
        max:max,
        min:min
    }

}

function getAllConcepts(encounters,weights) {

    var conceptsArr = [];

    for (var i = 0; i < encounters.length; i++) {
        var theEncounter = encounters[i];

        for (var j = 0; j < theEncounter.observations.length; j++) {
            var theQuestion = theEncounter.observations[j].question;

            // it is possible for a question not to have
            // a concept attached
            if (theQuestion.concept != null) {

                var searchTerm = theQuestion.concept.id;
                // There has got to be a better way to
                // build a unique array, right??
                var index = -1;
                for (var arrIndex = 0; arrIndex < conceptsArr.length; arrIndex++) {
                    if (conceptsArr[arrIndex].id === searchTerm) {
                        index = arrIndex;
                        break;
                    }
                }

                if (index === -1) {
                    theQuestion.concept.selected = true;
                    theQuestion.concept.seriesData = null;
                    conceptsArr.push(theQuestion.concept);
                }
            }
        }
    }

    var colors = colorUtility.getColorForData(conceptsArr.length);
    var coloredConceptsArr=conceptsArr.map((el,i)=>{
        if(weights===null){
            el.weight=""
        }else{ 
            el.weight=weights[el.id];
        }

        el.color=colors[i];
        return el;
    })
    var orderedColoredConceptsArr = coloredConceptsArr.sort((a,b)=>a.weight - b.weight);

    return orderedColoredConceptsArr; 
}

function getAllQuestions(encounters,weights) {

    var questionArr = [];

    for (var i = 0; i < encounters.length; i++) {
        var theEncounter = encounters[i];
        for (var j = 0; j < theEncounter.observations.length; j++) {
            if (theEncounter.observations[j].valueNumeric > -1) { // DAWNP: Accept zero as an Answer's value
                var theQuestion = theEncounter.observations[j].question;

                var searchTerm = theQuestion.id;

                // There has got to be a better way to build
                // a unique array, right??
                var index = -1;
                for (var arrIndex = 0; arrIndex < questionArr.length; arrIndex++) {
                    if (questionArr[arrIndex].id === searchTerm) {
                        index = arrIndex;
                        break;
                    }
                }

                if (index === -1) {
                    theQuestion.selected = true;
                    theQuestion.seriesData = null;
                    questionArr.push(theQuestion);
                }
            }

        }
    }

    var colors = colorUtility.getColorForData(questionArr.length);
    var coloredQuestionArr=questionArr.map((el,i)=>{
        if(weights===null){
            el.weight=""
        }else{
            el.weight=weights[el.concept.id];
        }

        el.color=colors[i];
        return el;
    })
    
    var orderedColoredQuestionsArr = coloredQuestionArr.sort((a,b)=>a.weight - b.weight);

    return orderedColoredQuestionsArr;
}

function getMinDate(encounters) {
    var date = moment.utc().format('YYYY-MM-DD');
    for (var i = 0; i < encounters.length; i++) {
        var encounterDate = moment.utc(encounters[i].datetime).format('YYYY-MM-DD');
        if (encounterDate < date) {
            date = encounterDate;
        }
    }
    return date;
}

function getMaxDate(encounters) {
    var date = moment.utc().format('YYYY-MM-DD');;

    if (encounters.length > 0) {
        date = moment.utc(encounters[0].datetime).format('YYYY-MM-DD');

        for (var i = 1; i < encounters.length; i++) {
            var encounterDate = moment.utc(encounters[i].datetime).format('YYYY-MM-DD');
            if (encounterDate > date) {
                date = encounterDate;
            }
        }
    }
    return date;
}

// Purpose, to create a plot data array, extracting from
// the encounters values relating to the selected
// concept.
// Since several observations in one encounter relate to
// the concept the values must be averaged
// Since no encounter covers all concepts, some concepts
// will be missing from a complete encounter
// Care must be taken not to place null or Nan in the
// plot array where an encounter is valid.
function getSeriesDataForConcept(encounters, concept, startDateIn, endDateIn) {
    var observationValues = [];
    // Ensure all encounters of the days selected are
    // included by setting Start time to 0:00 and End
    // time to 24:00 (ie 0:00 the next day)
    // Convert dates to epoch (milliseconds) 
    var startDate = startDateIn;
    var endDate = endDateIn;

    // We search through the observations in reverse
    // order because they are in descending time order.
    var maxEncountersIndex = encounters.length - 1;

    for (var i = maxEncountersIndex; i >= 0; i--) {
        var theEncounter = encounters[i];
        var theEncounterDate = moment.utc(theEncounter.datetime);
        // only process encounters falling within dates
        // selected
        if (moment(theEncounterDate.format('YYYY-MM-DD')).isSameOrAfter(startDate.format('YYYY-MM-DD')) && 
            moment(theEncounterDate.format('YYYY-MM-DD')).isSameOrBefore(endDate.format('YYYY-MM-DD'))) {

            var conceptTotal = 0;
            var numConcepts = 0;

            for (var b = 0; b < theEncounter.observations.length; b++) {
                var theObservation = theEncounter.observations[b];
                var theQuestionConcept = theObservation.question.concept;

                if ((theQuestionConcept.id === concept.id)){
                    //&& (theObservation.valueNumeric > 0)) { // DAWNP: Accept zero as an Answer's value
                    conceptTotal += theObservation.valueNumeric;
                    numConcepts++;
                }

            }// End of for loop that processes
            // Observations
            // Only calculate an average and push to
            // plot array if the concept was included in
            // the encounter
            // We do not want a null or Nan in the plot
            // array as this breaks the line
            if (conceptTotal > 0) {
                var conceptAverage = conceptTotal / numConcepts;
                var dataPoint = {x: theEncounterDate.valueOf(), y: conceptAverage};
                observationValues.push(dataPoint);
            }
        }// End of Check if encounter inside date
        // range
    }// //End of outer for loop. All encounters
    // covered
    return observationValues;
} // End of getSeriesDataForConcept

// Purpose, to create a plot data array, extracting from
// the encounters values relating to the selected
// question.
// Since no encounter covers all questions, some
// questions will be missing from a complete encounter
// Care must be taken not to place null or Nan in the
// plot array where an encounter is valid.
function getSeriesDataForQuestion(encounters, question, startDateIn, endDateIn) {
    var observationValues = [];

    // Ensure all encounters of the days selected are
    // included by setting Start time to 0:00 and End
    // time to 24:00 (ie 0:00 the next day)
    // Convert dates to epoch (milliseconds)
    var startDate = startDateIn;
    var endDate = endDateIn;

    // We search through the observations in reverse
    // order because they are in descending time order.
    var maxEncountersIndex = encounters.length - 1;

    for (var i = maxEncountersIndex; i >= 0; i--) {

        var theEncounter = encounters[i];
        var theEncounterDate = moment.utc(theEncounter.datetime);

        // only show encounters falling within dates
        // selected
        if (moment(theEncounterDate.format('YYYY-MM-DD')).isSameOrAfter(startDate.format('YYYY-MM-DD')) && 
            moment(theEncounterDate.format('YYYY-MM-DD')).isSameOrBefore(endDate.format('YYYY-MM-DD'))) {
            
            for (var b = 0; b < theEncounter.observations.length; b++) {
                var theObservation = theEncounter.observations[b];

                if (theObservation.question.id === question.id) {

                    var obsValue = theObservation.valueNumeric;
                    // only push the question value if
                    // the question does not have a 0
                    // value
                    if (obsValue > -1) { // DAWNP: Accept zero as an Answer's value
                        var dataPoint = {x: theEncounterDate.valueOf(), y: obsValue};
                        // The following line determines
                        // the correct index value of
                        // the datapoint, placing the
                        // oldest at timepoint 0.
                        observationValues.push(dataPoint);
                    }
                    break;
                }
            }// End of for loop that processes
            // observations

        }// End of Check if encounter inside date
        // range
    } // End of outer for loop. All encounters covered
    return observationValues;
}// End of getSeriesForQuestion function

/** 
 * Should think about setting up hourly labels when plenty of data available for same dates
*/
function getSimpleLabels(encounters, minDate, maxDate) {
    return encounters.filter(encounter => {
        const theEncounterDate = moment.utc(encounter.datetime).format('YYYY-MM-DD');
        return moment(theEncounterDate).isSameOrAfter(minDate) && moment(theEncounterDate).isSameOrBefore(maxDate);
    }).map(encounter => moment.utc(encounter.datetime).format('DD/MM')).reverse();
}


function getXAxisTicks(startDate, endDate) {
    // Calculate axis tick marks based on selected Start
    // and End dates, not encounter dates
    // Use date expressed as milliseconds since the
    // start of epoch (january 1970)
    var firstDateInMills = startDate;
    var lastDateInMills = endDate;
    var xAxisTicks = [];

    // We need to know what sort of range we are dealing
    // with
    // normalise firstDate to the start of the Day
    // 1000*60*60*24 is milliseconds in day
    // firstDateInMills =
    // (Math.floor(firstDateInMills/this.MILLISECONDS_IN_DAY))*this.MILLISECONDS_IN_DAY;
    // normalise lastDate to the start of the Day
    // following the last Day
    // lastDateInMills =
    // (Math.floor(lastDateInMills/this.MILLISECONDS_IN_DAY)+1)*this.MILLISECONDS_IN_DAY;
    // firstDateInMills =
    // Date.parse(this.getStartDate(startDate));
    // lastDateInMills =
    // Date.parse(this.getEndDate(endDate));

    var xAxisDayRange = getXAxisDayRange(firstDateInMills, lastDateInMills);

    // The day range is to determine the axis tick
    // interval.
    // If less than a week, the axis tick interval is to
    // be a tick every day
    // If two days or less the axis ticks to be at six
    // hourly intervals

    var nextDate;
    var DAYS_IN_WEEK = 7;
    var SINGLE_DAY_AXIS_THRESHOLD = 3; 
    // Case 1 range more than a week of data to be
    // plotted
    var nextDateInMills = 0;
    if (xAxisDayRange >= DAYS_IN_WEEK) {
        // Now we have start and end point.
        // We need seven discreet intervals

        // Progress by increments of delta from
        // xFirstData
        // Pushing the Data value and its DateString
        // equivalent onto xAxisData
        var delta = (lastDateInMills - firstDateInMills) / 7;
        for (nextDateInMills = firstDateInMills; nextDateInMills < lastDateInMills; nextDateInMills += delta) {
            nextDate = new Date(nextDateInMills);
            xAxisTicks.push([nextDateInMills, nextDate.toDateString()]);
        }
    } else if ((xAxisDayRange < DAYS_IN_WEEK)
        && (xAxisDayRange >= SINGLE_DAY_AXIS_THRESHOLD)) {

        for (nextDateInMills = firstDateInMills; nextDateInMills <= lastDateInMills; nextDateInMills += MILLISECONDS_IN_DAY) {
            nextDate = new Date(nextDateInMills);
            xAxisTicks.push([nextDateInMills, nextDate.toDateString()]);
        }
    } else {
        // Too few days to use days as tick intervals
        // Go down to six hourly intervals

        // Need to know how many milliseconds in 6 hours
        // (a quarter of a day)
        var millisecondsInQtrDay = MILLISECONDS_IN_DAY / 4;
        nextDateInMills = firstDateInMills;

        // Push tick marks at 6 hour intervals until
        // lastData exceeded
        while (nextDateInMills <= lastDateInMills) {
            nextDate = new Date(nextDateInMills);
            xAxisTicks.push([nextDateInMills, nextDate.toDateString()]);
            nextDateInMills += millisecondsInQtrDay;
        }
    }

    // rearrange last tick interval
    xAxisTicks[xAxisTicks.length - 1] = [lastDateInMills, new Date(lastDateInMills).toDateString()]

    if (xAxisDayRange < SINGLE_DAY_AXIS_THRESHOLD)
        xAxisTicks = setAxisTimeStrings(xAxisTicks);
    else
        xAxisTicks = setAxisDateStrings(xAxisTicks);

    return xAxisTicks;
}

const MILLISECONDS_IN_DAY = (1000 * 60 * 60 * 24);

function getXAxisDayRange(firstDateInMills, lastDateInMills) { 
    return (lastDateInMills - firstDateInMills) / MILLISECONDS_IN_DAY;
}

function setAxisDateStrings(xAxisTicks) {
    var labelDate;
    for (var tickCount = 0; tickCount < xAxisTicks.length; tickCount++) {
        labelDate = new Date(xAxisTicks[tickCount][0]);
        xAxisTicks[tickCount][1] = labelDate.getDate() + "/" + (labelDate.getMonth() + 1);
    }
    return xAxisTicks;
}

function setAxisTimeStrings(xAxisTicks) {
    var labelDate;
    for (var tickCount = 0; tickCount < xAxisTicks.length; tickCount++) {
        labelDate = new Date(xAxisTicks[tickCount][0]);
        xAxisTicks[tickCount][1] = labelDate.getDate() + " " + (labelDate.getHours() + ":00");
    }
    return xAxisTicks;
}

function getChartLabels(dateRange) {
    var cLabesl = getXAxisTicks(Date.parse(dateRange.minDate), Date.parse(dateRange.maxDate));
    return cLabesl.map((el) => {
        return el[1];
    });
}

function getEncountersDatesRange(encounters) {
    return {
        minDate: moment(getMinDate(encounters)),
        maxDate: moment(getMaxDate(encounters))
    }
}

function getConceptsPlotData(encounters, selectedConcepts, dateRange) {
    //console.log("getConceptsPlotData: ", dateRange); //encounters, selectedConcepts, 
    var conceptsPlotData = [];
    for (var i = 0; i < selectedConcepts.length; i++) {
        var concept = selectedConcepts[i]; 
        var serieData = getSeriesDataForConcept(encounters, concept, dateRange.minDate, dateRange.maxDate);
        var plotSeriesData = {
            label: concept.name,
            borderColor: concept.color,
            pointBorderColor: "rgba(0,0,0,0.3)",
            pointBackgroundColor: concept.color,
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            backgroundColor: 'transparent',
            fill: true,
            borderWidth: 2,
            data: serieData
        };
        conceptsPlotData.push(plotSeriesData);
    }
    //console.log("getConceptsPlotData: ", conceptsPlotData)
    return conceptsPlotData;
}

function getQuestionsPlotData(encounters, selectedQuestions, dateRange) {
    var questionsPlotData = [];
    for (var i = 0; i < selectedQuestions.length; i++) {
        var question = selectedQuestions[i]; 
        var serieData = getSeriesDataForQuestion(encounters, question, dateRange.minDate, dateRange.maxDate);
        var plotSeriesData = {
            label: question.text,
            borderColor: question.color,
            pointBorderColor: "#FFF",
            pointBackgroundColor: question.color,
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            backgroundColor: 'transparent',
            fill: true,
            borderWidth: 2,
            data: serieData
        };
        questionsPlotData.push(plotSeriesData);
    }
    return questionsPlotData;
}
