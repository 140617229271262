import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import { participantsActions } from 'actions';
import { PatientBanner } from 'components/PatientBanner/PatientBanner';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';

export const ParticipantEncounters = props => {
  const dispatch = useDispatch();

  const pid = parseInt(props.match.params.pid);
  const encounterId = parseInt(props.match.params.id);

  const [encounters, setEncounters] = useState([]);
  const [subtitle, setSubtitle] = useState(`(Day: - Event: )`);

  const {
    participantsLoading: loading,
    summary: {
      participant,
      encounters: storedEncounters
    }
  } = useSelector(state => state.participants) || {};

  useEffect(() => {
    if (storedEncounters) {
      const {
        eventNumber,
        dayNumber,
        details
      } = storedEncounters.find(item => item.id === encounterId);

      details.sort((a, b) => { return a.order < b.order ? -1 : 1 });
      setEncounters(details);
      setSubtitle(`(Day: ${dayNumber} - Event: ${eventNumber})`);
    }

  }, [participant, storedEncounters, loading, encounterId]);

  useEffect(() => {
    if (pid) {
      dispatch(participantsActions.getSummary(pid));
    }
  }, [pid, dispatch]);

  if (loading) {
    return <p>Loading...</p>
  }

  const columns = [
    {
      name: 'Question',
      selector: row => row.question,
      sortable: true,
      wrap: 'true',
    },
    {
      name: 'Symptom',
      selector: row => row.symptom,
      sortable: true,
      right: 'true',
    },
    {
      name: 'Answer',
      selector: row => row.answer,
      sortable: true,
      right: 'true',
    }
  ];

  return (
    <>
      <PatientBanner id={pid} participant={participant} />
      <PageWrapper title="Encounter Details for Service User" subtitle={subtitle} withPatientBanner>
        <DataTable
          columns={columns}
          data={encounters}
          pagination
          highlightOnHover
          noDataComponent={<h2>No data found</h2>}
        />
      </PageWrapper>
    </>
  )
}
