import moment from 'moment';

const convertGraphDataToNewFormat = (graphData) => {
  const labels = graphData.dataset.labels;
  const datasets = graphData.dataset.datasets;
  
  const data = labels.map((label, index) => {
    const newData = {};
    newData.name = label;
    
    datasets.forEach((dataset) => {
      const key = dataset.label;
      const value = dataset.data[index];
      newData[key] = value > 100 ? 100 : value;
    });
    
    return newData;
  });

  return data;
};

export const selectEncounterCompletionGraphDataCalculation = (encounters) => {
  if (!encounters) {
    return [];
  }

  var color = [
    'rgb(74, 133, 197)', // Blue
    'rgb(236, 99, 99)', // Pink
    'rgb(110, 91, 164)', // Purple
    'rgb(249, 180, 85)', // Yellow
    'rgb(67, 67, 66)', // Slate 
    'rgb(839, 30, 60)', // Black 
    'rgb(237, 237, 237)' // Pale Slate 
  ]
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  /* Number of Alarms based on Pacient Encounters */
  var alarmNumbers = Math.max.apply(Math, encounters.map(function (o) { return o.eventNumber; }))

  /* Weekly X Axis */
  var xAxysGraph01 = [];
  for (let i = 7; i >= 1; i--) {
    xAxysGraph01.push(days[moment().add(-i, 'days').day()]);
  }

  /* Monthly X Axis */
  var xAxysGraph02 = [];
  for (let i = 28; i >= 1; i--) {
    xAxysGraph02.push(moment().add(-i, 'days').format('D'));
  }

  //console.log("xAxysGraph01: ", xAxysGraph01);
  //console.log("xAxysGraph02: ", xAxysGraph02);

  if (alarmNumbers === 0) {
    alarmNumbers = 1;
  }

  const COMP_ENC = 1;
  const PART_ENC = 0.19;
  const MISS_ENC = 0.03;

  /* 3.	Encounters per day + Alarm Number (NOT am/pm) plotted to see if there are specific trends. */
  var filterGraph03 = [];
  var dataset03 = [];
  for (let i = 1; i <= alarmNumbers; i++) {
    filterGraph03[i] = encounters.filter(enc => moment(enc.datetime).valueOf() > moment().subtract(7, "day") && enc.eventNumber === i)
      .map(enc => ({
        datetime: enc.datetime,
        status: enc.status,
        day: days[moment(enc.datetime).weekday()],
        value: (enc.status === "COMPLETE" ? COMP_ENC * 2 : enc.status === "PARTIAL" ? PART_ENC * 2 : MISS_ENC * 2)
      }));

    filterGraph03[i] = xAxysGraph01.map((wday) =>
      filterGraph03[i].reduce((sum, enc) =>
        (enc.day === wday ? sum + enc.value : sum)
        , 0)
    )
    filterGraph03[i] = filterGraph03[i].map(item => (item * 100 / alarmNumbers));

    dataset03.push(
      {
        label: "Alarm",
        data: filterGraph03[i],
        backgroundColor: color[i]
      }
    )
  }

  var graphData03 = {
    name: "differenceAlarmNumbers7days",
    size: 6,
    dataset: {
      labels: xAxysGraph01,
      datasets: dataset03
    }
  }

  var DateStart, DateEnd;
  /* 1.	Volume of encounters in last 7 days plus indicator as to whether this has changed from previous week */
  var filterGraph01 = [];
  var dataset01 = [];
  var labelsGraph01 = [];

  DateEnd = moment();
  DateStart = moment().subtract(7, "day");
  labelsGraph01[1] = "Last 7 days";
  filterGraph01[1] = encounters.filter(enc => moment(enc.datetime) > DateStart)
    .map(enc => ({
      datetime: enc.datetime,
      status: enc.status,
      day: days[moment(enc.datetime).weekday()],
      label: "Last 7 days",
      value: (enc.status === "COMPLETE" ? COMP_ENC : enc.status === "PARTIAL" ? PART_ENC : MISS_ENC)
    }));

  DateEnd = moment().subtract(7, "day");
  DateStart = moment().subtract(14, "day");
  labelsGraph01[2] = "Previous 7 days";
  filterGraph01[2] = encounters.filter(enc => moment(enc.datetime) > DateStart && moment(enc.datetime) < DateEnd)
    .map(enc => ({
      datetime: enc.datetime,
      status: enc.status,
      day: days[moment(enc.datetime).weekday()],
      value: (enc.status === "COMPLETE" ? COMP_ENC : enc.status === "PARTIAL" ? PART_ENC : MISS_ENC)
    }));


  filterGraph01[1] = xAxysGraph01.map((wday) =>
    filterGraph01[1].reduce((sum, enc) =>
      (enc.day === wday ? sum + enc.value : sum)
      , 0)
  )
  filterGraph01[1] = filterGraph01[1].map(item => (item * 100 / alarmNumbers));

  filterGraph01[2] = xAxysGraph01.map((wday) =>
    filterGraph01[2].reduce((sum, enc) =>
      (enc.day === wday ? sum + enc.value : sum)
      , 0)
  )
  filterGraph01[2] = filterGraph01[2].map(item => (item * 100 / alarmNumbers));

  for (let i = 1; i <= 2; i++) {
    dataset01.push(
      {
        label: labelsGraph01[i],
        data: filterGraph01[i],
        backgroundColor: color[i]
      }
    )
  }

  var graphData01 = {
    name: "difference7Days",
    size: 6,
    dataset: {
      labels: xAxysGraph01,
      datasets: dataset01
    }
  }

  /* 2.	Volume of encounters in last 28 days plus indicator as to whether this has changed from previous equal period */
  var filterGraph02 = [];
  var dataset02 = [];
  var labelsGraph02 = [];

  DateEnd = moment();
  DateStart = moment().subtract(28, "day");
  // - DateStart / DateEnd : ", DateStart, DateEnd, moment(encounters[0].datetime), moment(encounters[0].datetime) > DateStart, moment(encounters[0].datetime).date(), moment(encounters[0].datetime).date() === 13, moment(encounters[0].datetime).date() === "13");
  labelsGraph02[1] = "Last 28 days";
  filterGraph02[1] = encounters.filter(enc => moment(enc.datetime) > DateStart)
    .map(enc => ({
      datetime: enc.datetime,
      status: enc.status,
      day: moment(enc.datetime).date().toString(),
      value: (enc.status === "COMPLETE" ? COMP_ENC : enc.status === "PARTIAL" ? PART_ENC : MISS_ENC)
    }));

  filterGraph02[1] = xAxysGraph02.map((wday) =>
    filterGraph02[1].reduce((sum, enc) =>
      (enc.day === wday ? sum + enc.value : sum)
      , 0)
  )
  filterGraph02[1] = filterGraph02[1].map(item => (item * 100 / alarmNumbers));

  DateEnd = moment().subtract(28, "day");
  DateStart = moment().subtract(56, "day");
  labelsGraph02[2] = "Previous 28 days";
  filterGraph02[2] = encounters.filter(enc => moment(enc.datetime) > DateStart && moment(enc.datetime) < DateEnd)
    .map(enc => ({
      datetime: enc.datetime,
      status: enc.status,
      day: moment(enc.datetime).date().toString(),
      value: (enc.status === "COMPLETE" ? COMP_ENC : enc.status === "PARTIAL" ? PART_ENC : MISS_ENC)
    }));

  filterGraph02[2] = xAxysGraph02.map((wday) =>
    filterGraph02[2].reduce((sum, enc) =>
      (enc.day === wday ? sum + enc.value : sum)
      , 0)
  )
  filterGraph02[2] = filterGraph02[2].map(item => (item * 100 / alarmNumbers));

  for (let i = 1; i <= 2; i++) {
    dataset02.push(
      {
        label: labelsGraph02[i],
        data: filterGraph02[i],
        backgroundColor: color[i]
      }
    )
  }

  var graphData02 = {
    name: "difference28Days",
    size: 8,
    dataset: {
      labels: xAxysGraph02,
      datasets: dataset02,
    },

  }

  var datasetGraphEncounters = [];

  datasetGraphEncounters.push(graphData01);
  datasetGraphEncounters.push(graphData03);
  datasetGraphEncounters.push(graphData02);

  return {
    dataset: datasetGraphEncounters,
    rechartData: datasetGraphEncounters.map((graphData) => ({
      name: graphData.name,
      data: convertGraphDataToNewFormat(graphData)
    }))
  }
}
