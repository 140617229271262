export const PrivacyPolicyContent = `
**CareLoop Health Ltd** (“CareLoop Health Ltd”, “we”, “us”, “our”)

We are company number 13219481 registered in the United Kingdom.  
Our registered office is at CareLoop Health Ltd, CTF, 46 Grafton Street, Manchester M13 9NT, England.

**Effective date:** 20/01/2021  
**Last reviewed:** 20/01/2021

## Introduction

CareLoop Health Ltd (“CareLoop Health Ltd”, “we”, “us”, “our”) designs and develops applications (“Apps”) to empower users to manage their health condition and improve their health outcomes. This privacy notice aims to inform you about how we collect and process any information that we collect from you, or that you provide to us. It covers information that could identify you (“personal information”) and information that could not. In the context of the law and this notice, “process” means collect, store, transfer, use or otherwise act on information. It tells you about your privacy rights and how the law protects you.

We are committed to protecting your privacy and the confidentiality of your personal information. Our policy is not just an exercise in complying with the law, but a continuation of our respect for you and your personal information.

We undertake to preserve the confidentiality of all information you provide to us, and hope that you reciprocate.

Our policy complies with the Data Protection Act 2018 (Act) accordingly incorporating the EU General Data Protection Regulation (GDPR).

The law requires us to tell you about your rights and our obligations to you in regard to the processing and control of your personal data. We do this now, by requesting that you read the information provided at [http://www.knowyourprivacyrights.org](http://www.knowyourprivacyrights.org)

Except as set out below, we do not share, or sell, or disclose to a third party, any information collected through our website.

## 1. Data Protection Officer

We have appointed a data protection officer (DPO) who is responsible for ensuring that our policy is followed.  
If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact our DPO at [dpo@careloophealth.com](mailto:dpo@careloophealth.com)

## 2. Data We Process

We may collect, use, store and transfer different kinds of personal data about you. We have collated these into groups as follows:

- **Your identity** includes information such as first name, last name, title, and other identifiers that you may have provided at some time.
- **Your contact information** includes information such as email address, telephone numbers and any other information you have given to us for the purpose of communication or meeting.
- **Technical data** includes your internet protocol (IP) address, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.

We may aggregate anonymous data such as statistical or demographic data for any purpose. Anonymous data is data that does not identify you as an individual. Aggregated data may be derived from your personal data but is not considered personal information in law because it does not reveal your identity.

For example, we may aggregate profile data to assess interest in a product or service. However, if we combine or connect aggregated data with your personal information so that it can identify you in any way, we treat the combined data as personal information and it will be used in accordance with this privacy notice.

### a) Special Category Personal Information

Special category personal information is data about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data.

We do not collect any special category personal information about you.

## 3. If You Do Not Provide Personal Information We Need

Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform that contract. In that case, we may have to stop providing a service to you. If so, we will notify you of this at the time.

## 4. The Bases on Which We Process Information About You

The law requires us to determine under which of six defined bases we process different categories of your personal information, and to notify you of the basis for each category.  
If a basis on which we process your personal information is no longer relevant then we shall immediately stop processing your data.  
If the basis changes then if required by law we shall notify you of the change and of any new basis under which we have determined that we can continue to process your information.

## 5. Information We Process Because We Have a Contractual Obligation With You

When you have an account on our website, or one created for you by a member of your clinical team, or otherwise agree to our terms and conditions, a contract is formed between you and us. In order to carry out our obligations under that contract, we must process the information you give us. Some of this information may be personal information.

We may use it in order to:

- verify your identity for security purposes
- provide you with our services

We process this information on the basis there is a contract between us, or that you have requested we use the information before we enter into a legal contract.

We shall continue to process this information until the contract between us ends or is terminated by either party under the terms of the contract.

## 6. Information We Process With Your Consent

Through certain actions when otherwise there is no contractual relationship between us, such as when you browse our website or ask us to provide you more information about our business, including our products and services, you provide your consent to us to process information that may be personal information.

Wherever possible, we aim to obtain your explicit consent to process this information, for example, by asking you to agree to our use of cookies.

We continue to process your information on this basis until you withdraw your consent or it can be reasonably assumed that your consent no longer exists.

You may withdraw your consent at any time by instructing us at [dpo@careloophealth.com](mailto:dpo@careloophealth.com). However, if you do so, you may not be able to use our website or our services further.

## 7. Information We Process for the Purposes of Legitimate Interests

We may process information on the basis there is a legitimate interest, either to you or to us, of doing so.

Where we process your information on this basis, we do so after having given careful consideration to:

- whether the same objective could be achieved through other means
- whether processing (or not processing) might cause you harm
- whether you would expect us to process your data, and whether you would, in the round, consider it reasonable to do so

For example, we may process your data on this basis for the purposes of:

- record-keeping for the proper and necessary administration of our business
- responding to unsolicited communication from you to which we believe you would expect a response
- protecting and asserting the legal rights of any party
- insuring against or obtaining professional advice that is required to manage business risk
- protecting your interests where we believe we have a duty to do so

## 8. Information We Process Because We Have a Legal Obligation

Sometimes, we must process your information in order to comply with a statutory obligation.  
For example, we may be required to give information to legal authorities if they so request or if they have the proper authorization such as a search warrant or court order.  
This may include your personal information.

## 9. Third Parties

We use third parties to process some of your personal data on our behalf. When we allow them access to your data, we do not permit them to use it for their own purposes. We have in place with each processor, a contract that requires them only to process the data on our instructions and to take proper care in using it. They are not permitted to keep the data after our relationship with them has ended.

## 10. Complaints Regarding Content on Our Website

If you complain about any of the content on our website, we shall investigate your complaint.  
If we feel it is justified or if we believe the law requires us to do so, we shall remove the content while we investigate.

## 11. Communicating With Us

When you contact us, whether by telephone, through our website, or by email, we collect the data you have given to us in order to reply with the information you need.  
We record your request and our reply in order to increase the efficiency of our business. We keep personally identifiable information associated with your message, such as your name and email address, so as to be able to track our communications with you to provide a high-quality service.

## 12. Complaining

When we receive a complaint, we record all the information you have given to us.  
We use that information to resolve your complaint. If your complaint reasonably requires us to contact some other person, we may decide to give that other person some of the information contained in your complaint. We do this as infrequently as possible, but it is a matter for our sole discretion as to whether we do give information, and if we do, what that information is.

We may also compile statistics showing information obtained from this source to assess the level of service we provide, but not in a way that could identify you or any other person.

## 13. Cookies

When you interact with our website, we try to make that experience simple and meaningful. When you visit the website, our web server sends a cookie to the hard disk of your computer. Cookies are small text files that are issued to your computer when you visit a website and which store and sometimes track information about your use of the site. For example, cookies are used to personalize web search engines and to store shopping lists of items a user has selected while browsing through a virtual shopping mall. Some of the cookies we use last only for the duration of your web session and expire when you close your browser. Other cookies are used to remember you when you return to the site and will last for longer.

We use cookies to:

- Remember that you have visited us before; this means we can identify the number of unique visitors we receive. This allows us to make sure we have enough capacity for the number of users that we get.
- Collect statistical information about how you use the site (including how long you spend there) and where you have come to the site from. We collect this data so that we can improve the website and learn which parts are most popular with visitors.
- Improve speed site navigation and recognize your access rights on the site.

Your web browser should allow you to delete any you choose. It also should allow you to prevent or limit their use.

## 14. Data May Be Processed Outside the European Union

Our websites are hosted in the United Kingdom. We may also use outsourced services in countries outside the European Union from time to time in other aspects of our business.  
Accordingly, data obtained within the UK or any other country could be processed outside the European Union. For example, some of the software our website uses may have been developed in South America.

We use the following safeguards with respect to data transferred outside the European Union:

- The data protection clauses in our contracts with data processors include transfer clauses written by or approved by a supervisory authority in the European Union, specifically that in the United Kingdom.
- We comply with a code of conduct approved by a supervisory authority in the European Union, specifically that in the United Kingdom.

## 15. Your Duty to Inform Us of Changes

It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes.

## 16. Access to Your Personal Information

To obtain a copy of any information that is not provided on our website, you should contact us to make that request.  
After receiving the request, we will tell you when we expect to provide you with the information, and whether we require any fee for providing it to you.

## 17. Removal of Your Information

If you wish us to remove personally identifiable information, you should contact us at [dpo@careloophealth.com](mailto:dpo@careloophealth.com) to make your request. This may limit the service we can provide to you.

When we receive any request to access, edit or delete personal identifiable information, we shall first take reasonable steps to verify your identity before granting you access or otherwise taking any action. This is important to safeguard your information.

## 18. Use of Site by Children

We do not sell products or provide services for purchase by children, nor do we market to children.

## 19. Encryption of Data Sent Between Us

We use Secure Sockets Layer (SSL) certificates to verify our identity to your browser and to encrypt any data you give us.

Whenever information is transferred between us, you can check that it is done so using SSL by looking for a closed padlock symbol or other trust mark in your browser’s URL bar or toolbar.

## 20. How You Can Complain

If you are not happy with our privacy policy or if you have any complaints, then you should tell us by contacting [dpo@careloophealth.com](mailto:dpo@careloophealth.com)

If a dispute is not settled, then we hope you will agree to attempt to resolve it by engaging in good faith with us in a process of mediation or arbitration.

If you are in any way dissatisfied about how we process your personal information, you have a right to lodge a complaint with the Information Commissioner's Office (ICO). This can be done at [https://ico.org.uk/make-a-complaint/](https://ico.org.uk/make-a-complaint/). We would, however, appreciate the opportunity to talk to you about your concern before you approach the ICO.

## 21. Retention Period for Personal Data

Except as otherwise mentioned in this privacy notice, we keep your personal information only for as long as required by us:

- to provide you with the services you have requested;
- to comply with other law, including for the period demanded by our tax authorities;
- to support a claim or defence in court.

## 22. Compliance With the Law

Our privacy policy has been compiled so as to comply with the law of every country or legal jurisdiction in which we aim to do business. If you think it fails to satisfy the law of your jurisdiction, we should like to hear from you.  
However, ultimately it is your choice as to whether you wish to use our website.

## 23. Review of This Privacy Policy

We may update this privacy notice from time to time as necessary. The terms that apply to you are those posted here on our website on the day you use our website. We advise you to print a copy for your records.

If you have any question regarding our privacy policy, please contact us at [dpo@careloophealth.com](mailto:dpo@careloophealth.com)

`;