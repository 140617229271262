import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { Bar, Legend, BarChart, XAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

import { participantsActions } from '../../../actions';
import { selectEncounterCompletionGraphDataCalculation } from '../selectors/select-encounters-graph-data';


const GraphHeader = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
  text-align: center;
`;

const formatEngagementTooltip = (value, name, props) => {
  return [
    `${value}% engagement`,
    name
  ]
};

export const EncountersOverview = () => {
  const { pid: participantId } = useParams();

  const [alarmsPast7daysStats, setAlarmsPast7daysStats] = useState([]);
  const [encounterCompletion28dayStats, setEncounterCompletion28dayStats] = useState([]);

  const {
    summary
  } = useSelector((state) => state.participants);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(participantsActions.getSummary(participantId));
  }, [dispatch, participantId]);

  useEffect(() => {
    if (summary) {
      const chartData = selectEncounterCompletionGraphDataCalculation(summary.encounters);

      if (chartData.length === 0) {
        return;
      }

      const last28DaysStats = chartData.rechartData.find((item) => item.name === 'difference28Days');
      const last7daysAlarmsStats = chartData.rechartData.find((item) => item.name === 'differenceAlarmNumbers7days');
      
      setEncounterCompletion28dayStats(last28DaysStats.data);
      setAlarmsPast7daysStats(last7daysAlarmsStats.data);
    }
  }, [summary]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <GraphHeader>EWS numbers per day for the current week</GraphHeader>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={alarmsPast7daysStats}>
            <CartesianGrid strokeDasharray="4" />
              <XAxis dataKey="name" />
              <Bar dataKey="Alarm" fill="#bd1000" />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
      <Row>
      <Col xs={12}>
          <GraphHeader>Encounters per day between past 28 days and previous 28 days</GraphHeader>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={encounterCompletion28dayStats}>
            <CartesianGrid strokeDasharray="4" />
              <XAxis dataKey="name" />
              <Tooltip formatter={formatEngagementTooltip}/>
              <Legend verticalAlign="top" height={36}/>
              <Bar dataKey="Last 28 days" fill="#08838f" />
              <Bar dataKey="Previous 28 days" fill="#800080" />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  )
};
