/*eslint-disable*/
import React, { Component } from "react";
import { connect } from 'react-redux';
import Select from "react-select";

import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from "reactstrap";

import Switch from "react-bootstrap-switch";

import Datetime from "react-datetime";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { participantsActions } from '../../actions';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';

class ExportEncountersData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showParticipants: true,
      showDates: true,
      cparticipant: 0,
      exportStartDate: null,
      exportEndDate: null
    };

    if (props.lparticipants === undefined || props.lparticipants === null || props.lparticipants.length === 0) {
      props.getAll();
    }
  }

  handleSwitch(elem, state) {
    this.setState({ [elem.props.name]: state });
  }
  handleChangeStartDate(date) {
    this.setState({ exportStartDate: Datetime.moment(date) });
  }
  handleChangeEndDate(date) {
    this.setState({ exportEndDate: Datetime.moment(date) });
  }


  doExport() {
    var urlSuffix = "";
    if (this.state.showParticipants === true) {

      if (this.state.showDates === true) {
        //We're done. the baseUrl is already complete.
      } else {
        var sdate = this.state.exportStartDate;
        var edate = this.state.exportEndDate;
        if (sdate === null) sdate = Datetime.moment();
        if (edate === null) edate = Datetime.moment();
        urlSuffix = `${sdate.format('YYYYMMDD')}/${edate.format('YYYYMMDD')}`;
      }

    } else {

      if (this.state.cparticipant > 0) {
        urlSuffix = `${this.state.cparticipant}/`;
      }
      if (this.state.showDates === true) {
        //We're done!	
      } else {
        var sdate = this.state.exportStartDate;
        var edate = this.state.exportEndDate;
        if (sdate === null) sdate = Datetime.moment();
        if (edate === null) edate = Datetime.moment();
        urlSuffix = `${urlSuffix}${sdate.format('YYYYMMDD')}/${edate.format('YYYYMMDD')}`;
      }

    }
    location.href = `/clintouch-server/api/export/${urlSuffix}`;
  };

  render() {
    return (
      <PageWrapper title="Export Encounter Data">
        <>
          <Row>
            <Col xs={12} md={6}>
              <span className="progress-badge pull-left">Export data for all service users</span>
              <br />
              <Switch
                name="showParticipants"
                onChange={(el, state) => this.handleSwitch(el, state)}
                onText={<i className="now-ui-icons ui-1_check" />}
                offText={
                  <i className="now-ui-icons ui-1_simple-remove" />
                }
                defaultValue={true}
              />{" "}
              <br />
              {this.state.showParticipants === false &&
                <>
                  <br />
                  <br />

                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    placeholder="Select a Participant"
                    name="cparticipant"
                    options={this.props.lparticipants}
                    onChange={value =>
                      this.setState({ cparticipant: value.value })
                    } />
                </>
              }
            </Col>
            <Col xs={12} md={6}>
              <span className="progress-badge pull-left">Export encounters for all dates</span>
              <br />
              <Switch
                name="showDates"
                onChange={(el, state) => this.handleSwitch(el, state)}
                onText={<i className="now-ui-icons ui-1_check" />}
                offText={
                  <i className="now-ui-icons ui-1_simple-remove" />
                }
                defaultValue={true}
              />{" "}
              <br />
              {this.state.showDates === false &&
                <>
                  <label>Start  Date</label>
                  <Datetime
                    value={this.state.exportStartDate}
                    onChange={this.handleChangeStartDate.bind(this)}
                    timeFormat={false}
                    inputProps={{ placeholder: "Start Date" }} />
                  <label>End  Date</label>
                  <Datetime
                    value={this.state.exportEndDate}
                    onChange={this.handleChangeEndDate.bind(this)}
                    timeFormat={false}
                    inputProps={{ placeholder: "End Date" }} />
                </>
              }
            </Col>
          </Row>

          <br />
          <Row>
            <Col xs={12} md={12}>
              <Button className="btn-fill btn-info pull-right" type="submit"
                onClick={this.doExport.bind(this)}
              >
                Get Export
              </Button>
            </Col>
          </Row></>
      </PageWrapper>
    );
  }
}

function mapState(state) {
  const { participants, participantsLoading } = state.participants;
  if (participants) {
    var lparticipants = participants.map((el) => {
      try {
        return {
          value: el.participant.id,
          label: el.participant.user.login
        };
      } catch (e) {
        //console.log("Error with user: ", el);
        //console.log("Error: ", e.message);
        return null;
      }
    })
  }

  return { lparticipants, participantsLoading };
}

const actionCreators = {
  getAll: participantsActions.getAll,
  getAllMy: participantsActions.getAllMy
};

const connectedExportEncountersData = connect(mapState, actionCreators)(ExportEncountersData);
export { connectedExportEncountersData as ExportEncountersData }; 