import { genericConstants } from '../constants';
import { overviewstatsService} from '../services'; 
import { alertActions } from '.';

export const overviewStatsActions = {
    getAll,
    getAllMy
  };
  
  function getAll() {
    return dispatch => {
      dispatch(request());
  
      return new Promise((resolve, reject) => {
        overviewstatsService.getAllStats()
          .then(
            response => {
              //console.log("getAllStats success:", response);
              dispatch(success(response));
              resolve(response); // Resolve the promise with the response
            },
            error => {
              //console.log("getAllStats failure:", error.toString());
              dispatch(failure(error.toString()));
              dispatch(alertActions.error(error.toString()));
              reject(error); // Reject the promise with the error
            }
          );
      });
    };
  
    function request() { return { type: genericConstants.OVERVIEWSTATS_REQUEST } }
    function success(items) { return { type: genericConstants.OVERVIEWSTATS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.OVERVIEWSTATS_FAILURE, error } }
  }
  
  function getAllMy(pId) {
    return dispatch => {
      dispatch(request());
  
      return new Promise((resolve, reject) => {
        overviewstatsService.getMyStats(pId)
          .then(
            response => {
              //console.log("getAllMy success:", response);
              dispatch(success(response));
              resolve(response); // Resolve the promise with the response
            },
            error => {
              //console.log("getAllMy failure:", error.toString());
              dispatch(failure(error.toString()));
              dispatch(alertActions.error(error.toString()));
              reject(error); // Reject the promise with the error
            }
          );
      });
    };
  
    function request() { return { type: genericConstants.OVERVIEWSTATS_REQUEST } }
    function success(items) { return { type: genericConstants.OVERVIEWSTATS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.OVERVIEWSTATS_FAILURE, error } }
  }











/*
export const overviewStatsActions = {
    getAll,
    getAllMy
};

function getAll() {
    return dispatch => {
        dispatch(request());

        overviewstatsService.getAllStats()
            .then(
                response => {
                    console.log("getAllStats success:", response);
                    dispatch(success(response));
                },
                error => {
                    console.log("getAllStats failure:", error.toString());
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.OVERVIEWSTATS_REQUEST } }
    function success(items) { return { type: genericConstants.OVERVIEWSTATS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.OVERVIEWSTATS_FAILURE, error } }
}

function getAllMy(pId) {
    return dispatch => {
        dispatch(request());

        overviewstatsService.getMyStats(pId)
            .then(
                response => {
                    console.log("getAllMy success:", response);
                    dispatch(success(response));
                },
                error => {
                    console.log("getAllMy failure:", error.toString());
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.OVERVIEWSTATS_REQUEST } }
    function success(items) { return { type: genericConstants.OVERVIEWSTATS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.OVERVIEWSTATS_FAILURE, error } }
}*/