/*eslint-disable*/
import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { Button } from "reactstrap";

import { isStudyStarted } from '../../helpers';
import { settingsActions } from '../../actions';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.props.getAll();
    this.state = {
      data: []
    };
  }

  render() {
    const { settings } = this.props;

    if (settings && settings.length > 0) {
      var dataItems = settings.map((el) => {

        var actions = <div className="actions-right">
          <Button
            onClick={() => {
              this.props.history.push({
                pathname: `/admin/settings/${el.id}/edit`,
                state: { theSetting: el }
              });
            }}
            className="btn-icon btn-round"
            color="warning"
            size="sm">
            <i className="fa fa-edit" />
          </Button>
        </div>;

        if (isStudyStarted()) {
          actions = <div className="actions-right"></div>;
        }

        return {
          id: el.id,
          type: el.type,
          title: el.title,
          value: el.value + " " + el.uom,
          actions: actions
        };
      });
    }

    return (
      <PageWrapper title="Settings">
        <ReactTable
          data={dataItems}
          filterable
          columns={[
            {
              Header: "Setting",
              accessor: "title"
            },
            {
              Header: "Value",
              accessor: "value"
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false
            }
          ]}
          defaultPageSize={10}
          showPaginationBottom={true}
          className="-striped -highlight"
        />
      </PageWrapper>
    );
  }
}

function mapState(state) {
  const { settings } = state.settings;
  return { settings };
}

const actionCreators = {
  getAll: settingsActions.getAll
};

const connectedSettings = connect(mapState, actionCreators)(Settings);
export { connectedSettings as Settings }; 