/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';

import { overviewStatsActions } from '../../actions';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { EncountersPieChart } from './EncountersPieChart';
import { NumberChart } from './NumberChart';

export const OverviewStats = ({ location }) => {
  const currentLocation = useLocation();

  const [state, setState] = useState({
    totalNoOfParticipantsWithEncounters: '',
    totalNoOfParticipants: '',
    totalNoOfEncounters: '',
    totalNoOfPartialEncounters: '',
    totalNoOfCompleteEncounters: '',
    totalNoOfMissingEncounters: '',

    lastWeekPartial: '',
    lastWeekComplete: '',
    lastWeekMissing: '',
    lastMonthPartial: '',
    lastMonthComplete: '',
    lastMonthMissing: '',

    amountUsersCompleteWeek: '',
    amountUsersMissingWeek: '',
    amountUsersComplete3Day: '',
    amountUsersMissing3Day: '',
    lastEncounter: '',
    data: [],
  });

  const dispatch = useDispatch();
  const { overviewStats, overviewStatsLoading } = useSelector((state) => state.overviewStats);

  useEffect(() => {
    if (currentLocation.pathname === '/admin/dashboard') {
      dispatch(overviewStatsActions.getAll())
        .then((response) => {
          const stats = response;
          setState((prevState) => ({
            ...prevState,
            ...stats,
          }));
        });
    } else {
      const myId = window.localStorage.getItem('id');
      dispatch(overviewStatsActions.getAllMy(myId))
        .then((response) => {
          const stats = response;
          setState((prevState) => ({
            ...prevState,
            ...stats,
          }));
        })
        .catch((error) => {
          // Handle the error if needed
        });
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (overviewStats) {
      setState((prevState) => ({
        ...prevState,
        ...overviewStats,
        lastEncounter: overviewStats.lastEncounter
          ? new Date(overviewStats.lastEncounter).toLocaleDateString('en-UK', {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })
          : '',
      }));
    }
  }, [overviewStats]);

  if (overviewStatsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <PageWrapper title="Dashboard">
      <Row>
        <Col xs={12} md={12}>
          <p className="mb-4">Last encounter received: {moment().diff(moment(state.lastEncounter), 'days')} day(s) ago</p>
        </Col>
      </Row>
      <Row>
        <Col xs={4} md={4}>
          <EncountersPieChart
            completed={state.lastWeekComplete}
            partial={state.lastWeekPartial}
            missed={state.lastWeekMissing}
            title="Last weeks encounters"
          />
        </Col>
        <Col xs={4} md={4}>
          <EncountersPieChart
            completed={state.lastMonthComplete}
            partial={state.lastMonthPartial}
            missed={state.lastMonthMissing}
            title="Last months encounters"
          />
        </Col>
        <Col xs={4} md={4}>
          <EncountersPieChart
            completed={state.totalNoOfCompleteEncounters}
            partial={state.totalNoOfPartialEncounters}
            missed={state.totalNoOfMissingEncounters}
            title="All encounters"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4} md={4}>
          <NumberChart title="Service Users" number={state.totalNoOfParticipants} label={'Total'} />
        </Col>
        <Col xs={4} md={4}>
          <EncountersPieChart
            completed={state.amountUsersComplete3Day}
            missed={state.amountUsersMissing3Day}
            title="Last 3 days"
          />
        </Col>
        <Col xs={4} md={4}>
          <EncountersPieChart
            completed={state.amountUsersCompleteWeek}
            missed={state.amountUsersMissingWeek}
            title="Last week"
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};
