import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { participants } from './participants.reducer';
import { providers } from './providers.reducer';
import { alarms } from './alarms.reducer';
import { questions } from './questions.reducer';
import { forms } from './forms.reducer';
import { answers } from './answers.reducer';
import { screens } from './screens.reducer';
import { settings } from './settings.reducer';
import { lookups } from './lookups.reducer';
import { overviewStats } from './overviewStats.reducer';
import { concepts } from './concepts.reducer';
import { alerts } from './alerts.reducer';

const rootReducer = combineReducers({
  authentication,
  overviewStats,
  participants,
  providers,
  alarms,
  alerts,
  concepts,
  questions,
  forms,
  answers,
  screens,
  settings,
  lookups
});

export default rootReducer;