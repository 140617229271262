import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Alert, Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import * as Yup from 'yup';
import styled from 'styled-components';

import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { PatientBanner } from 'components/PatientBanner/PatientBanner';
import { alertActions, participantsActions } from '../../actions';
import { alertResolutions } from '../../constants/alert.constants';
import { alertsService } from '../../services';

const ResolutionDropdownInput = ({ label, touched, ...props }) => {
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (touched && (!props.value || props.value === 'NONE')) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [props, touched]);

  return (
    <FormGroup className="flex-grow w-60 mr-5">
      <Label for={props.id || props.name}>{label}</Label>
      <Input type="select" {...props} invalid={invalid}>
        {Object.entries(alertResolutions).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </Input>
      {invalid && <p className='text-red-600 text-xs my-1'>{props.error}</p>}
    </FormGroup>
  );
}

const SuccessAlert = styled(Alert)`
  color: white;
  background-color: #46a0a3 !important;
`;

export const AlertsEdit = withRouter(({ history }) => {
  const { pid: participantId, alertid: alertId } = useParams();

  const dispath = useDispatch();
  const participant = useSelector((state) => state.participants.summary);
  const { alerts: storedAlerts = [] } = useSelector((state) => state.alerts);

  const [pageTitle, setPageTitle] = useState('Handle Alert');
  const [pageSubtitle, setPageSubtitle] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [formInitialValues, setFormInitialValues] = useState(
    {
      notes: '',
      resolution: '',
      date: moment().format('YYYY-MM-DD'),
      time: moment().format('HH:mm'),
    }
  );

  const validationSchema = Yup.object({
    notes: Yup.string().required('Notes are required'),
    resolution: Yup.string().required('Resolution is required'),
    date: Yup.string().required('Resolution date is required'),
    time: Yup.string().required('Resolution time is required'),
  });

  useEffect(() => {
    if (participantId) {
      dispath(participantsActions.getParticipant(participantId));
      dispath(alertActions.getParticipantAlerts(participantId));
    }
  }, [dispath, participantId]);

  useEffect(() => {
    if (!alertId || !storedAlerts.length) return;

    const alert = storedAlerts.find((alert) => alert.id === Number(alertId))
    const date = alert.handledDate ? moment(alert.handledDate) : moment();

    setFormInitialValues({
      breachedDomains: alert.breachedConceptNames.join(', '),
      notes: alert.alertResolutionNotes ?? '',
      resolution: alert.alertResolution ?? 'NONE',
      date: date.format('YYYY-MM-DD'),
      time: date.format('HH:mm'),
    });

    setPageTitle(`Handle EWS (ID: ${alert.id})`);
    setPageSubtitle(`EWS Date: ${moment(alert.alertDate).format("MMM D, YYYY h:mm:ss A")}`);
  }, [alertId, storedAlerts]);

  const handleSubmitAction = (values, { setSubmitting }) => {
    if (values.resolution === 'NONE') {
      return setSubmitting(false);
    };

    setSubmitting(true);

    const dateMoment = moment(values.date, 'YYYY-MM-DD');

    dateMoment.set({
      hour: moment(values.time, 'HH:mm:ss').hour(),
      minute: moment(values.time, 'HH:mm:ss').minute(),
      second: moment(values.time, 'HH:mm:ss').second()
    });

    const alert = {
      date: dateMoment.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      notes: values.notes,
      resolution: values.resolution,
    }

    alertsService.handleAlert(alert, alertId)
      .then((response) => {
        if (!response) {
          throw new Error('Failed to update the EWS');
        }

        setApiError(null);
        history.push(`/admin/participants/${participantId}/summary`);
      })
      .catch((error) => {
        setApiError(error);
      })
      .finally(() => {
        setSubmitting(false);
        setIsSubmitted(true);
      });
  };

  const renderAlert = () => {
    if (apiError) {
      return (
        <Alert color="danger">
          Alert failed to successfully update
        </Alert>
      );
    }

    if (isSubmitted) {
      return (
        <SuccessAlert>
          Alert updated successfully
        </SuccessAlert>
      );
    }
  }

  return (
    <>
      <PatientBanner participant={participant} />
      <PageWrapper title={pageTitle} subtitle={pageSubtitle} withPatientBanner>
        {renderAlert()}
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitAction}
        >
          {({ handleSubmit, isSubmitting, errors, touched }) => (
            <Form onSubmit={handleSubmit}>
              <section className='flex'>
                <Field
                  as={ResolutionDropdownInput}
                  name="resolution"
                  type="select"
                  label="Resolution"
                  id="resolution"
                  error="This field is required"
                  touched={touched.resolution}
                />

                <FormGroup className="flex-grow w-20 mr-5">
                  <Label for="date">Date</Label>
                  <Field
                    name="date"
                    type="date"
                    as={Input}
                    invalid={touched.date && !!errors.date}
                  />
                  <ErrorMessage name="date" component={FormFeedback} />
                </FormGroup>

                <FormGroup className="flex-grow w-20">
                  <Label for="time">Date</Label>
                  <Field
                    name="time"
                    type="time"
                    as={Input}
                    invalid={touched.time && !!errors.time}
                  />
                  <ErrorMessage name="date" component={FormFeedback} />
                </FormGroup>
              </section>

              <FormGroup>
                <Label for="breachedDomains">Breached Domains</Label>
                <Field
                  name="breachedDomains"
                  as={Input}
                  type="textarea"
                  disabled
                  className="!text-black"
                />
              </FormGroup>

              <FormGroup>
                <Label for="notes">Notes</Label>
                <Field
                  name="notes"
                  as={Input}
                  type="textarea"
                  invalid={touched.notes && !!errors.notes}
                />
                <ErrorMessage name="notes" component={FormFeedback} />
              </FormGroup>

              <div className="flex flex-row justify-between">
                <Button className="btn-fill bg-red-600" type="submit" onClick={() => history.goBack()}>
                  Cancel
                </Button>
                <Button className="btn btn-primary float-right" disabled={isSubmitting} type="submit">Submit</Button>
              </div>
            </Form>
          )}
        </Formik>
      </PageWrapper>
    </>
  );
});
