
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { NavLink } from 'reactstrap';
import moment from 'moment';

import { alertActions } from '../../../actions';
import { alertResolutions } from '../../../constants/alert.constants';

const IconCell = styled.div`
  i {
    margin: 2px;

    &.fa-times-circle {
      color: #bd1000;
    }

    &.fa-check-circle {
      color: #00a11b;
    }
  }
`;

const ExpandedRowComponentWrapper = styled.div`
  margin-left: 50px;
  padding: 20px;
  background-color: #f5f5f5;

  h4 {
    text-decoration: underline;
  }

  section {
    margin-bottom: 10px;
  }
`;

const HandledIcon = ({ id, handled }) => {
  return (
    <IconCell>
      {Boolean(handled) ?
        <i key={id} className="fas fa-check-circle"></i> :
        <i key={id} className="fas fa-times-circle"></i>
      }
    </IconCell>
  )
};

export const AlertsList = ({ participantId }) => {
  const dispath = useDispatch();

  const {
    alerts,
    alertsLoading,
  } = useSelector(state => state.alerts);

  useEffect(() => {
    dispath(alertActions.getParticipantAlerts(participantId));
  }, [dispath, participantId]);

  const renderDateCell = (date) => {
    if (!date) {
      return 'N/A';
    }

    return moment(date).format('DD/MM/YYYY')
  }

  const ExpandedRowComponent = ({ data }) => {
    const resolutionNotes = data.alertResolutionNotes ? data.alertResolutionNotes : 'N/A';

    return (
      <ExpandedRowComponentWrapper>
        <section>
          <h4>Resolution Notes</h4>
          <p>{resolutionNotes}</p>
        </section>

        <section>
          <h4>Breached Domains</h4>
          <p>{data.breachedConceptNames.join(', ', '')}</p>
        </section>

        <section>
          <h4>Handled by</h4>
          <p>{data.handlerUsername}</p>
        </section>
      </ExpandedRowComponentWrapper>
    )
  };

  const columns = [
    {
      name: <p>Date of alert</p>,
      selector: row => renderDateCell(row.alertDate),
      sortable: 'true',
      sortFunction: (a, b) => moment(a.alertDate).unix() - moment(b.alertDate).unix(),
    },
    {
      name: <p>Date of resolution</p>,
      selector: row => renderDateCell(row.handledDate),
      sortable: 'true',
      sortFunction: (a, b) => moment(a.handledDate).unix() - moment(b.handledDate).unix(),
    },
    {
      name: <p>Date of recorded</p>,
      selector: row => renderDateCell(row.recordedDate),
      sortable: 'true',
      sortFunction: (a, b) => moment(a.recordedDate).unix() - moment(b.recordedDate).unix(),
    },
    {
      name: <p>Resolution type</p>,
      selector: row => <div className='p-1'>{alertResolutions[row.alertResolution]}</div>,
      sortable: 'true',
      wrap: 'true',
    },
    {
      name: <p>Handled?</p>,
      selector: row => <HandledIcon id={row.id} handled={row.handled} />,
      sortable: true,
      sortFunction: (a, b) => a.handled - b.handled,
      width: '50',
      $center: 'true',
    },
    {
      name: <p>Actions</p>,
      cell: row => (
        <>
          <NavLink href={`/clintouch/admin/participants/${participantId}/alerts/${row.id}/edit`}>Edit</NavLink>
        </>
      ),
      $center: 'true',
    },
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={alerts}
        pagination
        progressPending={alertsLoading}
        progressComponent={<h2>Loading...</h2>}
        noDataComponent={<h2>No data found</h2>}
        expandableRows
        expandableRowsComponent={ExpandedRowComponent}
      />
    </>
  )
}
