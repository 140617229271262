import { genericConstants } from '../constants';
import { formsService} from '../services'; 
import { alertActions } from '.';

export const formsActions = {
    getForms
};

function getForms() {
    
    return dispatch => {
        dispatch(request());

        //console.log("formsActions.getForms");
        formsService.getForms()
            .then(
                response => {
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.FORMS_REQUEST } }
    function success(items) { return { type: genericConstants.FORMS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.FORMS_FAILURE, error } }
}