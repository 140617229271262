/*eslint-disable*/
import React, { Component } from "react";
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

import { isStudyStarted } from '../../helpers';
import { alarmsActions } from '../../actions';
import { alarmsService } from '../../services';

class Alarms extends Component {
  constructor(props) {
    super(props);
    this.props.getAll();
    this.state = {
      data: []
    };
  }

  render() {
    var { alarms } = this.props;

    function pad(num) {
      var s = "0" + num;
      return s.substr(s.length-2);
    }

    if (alarms && alarms.length > 0) {
      var dataItems = alarms.map((el) => {

        var actions = <div className="actions-right">
            <Button
              onClick={() => {
                askConfirmationDelete(el.id);
              }}
              className="btn-icon btn-round"
              color="danger"
              size="sm">
              <i className="fa fa-trash" />
            </Button>
            
            {"  "}
            <Button
              onClick={() => {
                this.props.history.push({
                  pathname: `/admin/alarms/${el.id}/edit`,
                  state: { theAlarm: el }
                });
              }}
              className="btn-icon btn-round"
              color="warning"
              size="sm">
              <i className="fa fa-edit" />
            </Button>
          </div>;
        
        if(isStudyStarted()){
          actions = <div className="actions-right"></div>;
        }

        return {
          id: el.id,
          day: el.day,
          alarmNumber: el.alarmNumber,
          hour: el.hour,
          alternativeForm: el.alternativeForm === true ? "YES" : "",
          minute: el.minute,
          time: pad(el.hour) + ":" +  pad(el.minute),
          actions: actions
        };
      });
    }

    const deleteAlarm =(id) => {
      alarmsService.deleteAlarm(id).then(r => {
          if(r){
            this.setState({ alert: null })

            dataItems = dataItems.filter(function( obj ) {
              return obj.id !== id;
            });
  
            this.props.alarms = this.props.alarms.filter(function( obj ) {
                return obj.id !== id;
            });

            alarms = alarms.filter(function( obj ) {
              return obj.id !== id;
            });
  
            this.setState({data: this.state});
          } else {
            this.setState({
                alert: (
                    <SweetAlert
                        danger
                        style={{ display: "block", marginTop: "100px" }}
                        title="There was an error trying to delete the alarm"
                        onConfirm={() => this.setState({ alert: null })}
                        confirmBtnBsStyle="info"
                    >
                    </SweetAlert>
                )
            });
          }
      })
    }
  
    const askConfirmationDelete =(id) => {
      this.setState({ alert: null })
      this.setState({
          alert: (
            <SweetAlert
              danger
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              title="Are you sure?"
              style={{ display: "block", marginTop: "100px" }}
              onConfirm={() => deleteAlarm(id)}
              onCancel={() => this.setState({ alert: null })}
              //focusCancelBtn
            >
              You will not be able to recover this Alarm!
            </SweetAlert>
          )
      });
    }

    var buttonAdd = <Button color="info" className="btn-round btn-icon pull-right"
                    onClick={() => {
                      this.props.history.push("/admin/alarms/0/edit");
                    }}>
                    <i className="now-ui-icons ui-1_simple-add" />
                  </Button>;

    if(isStudyStarted()){
      buttonAdd = "";
    }

    return (
      <>
        {this.state.alert}
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  {buttonAdd}
                  <CardTitle tag="h4">Alarms</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={dataItems}
                    filterable
                    columns={[
                      {
                        Header: "Day",
                        accessor: "day",
                        style: {"textAlign":"right"},
                        maxWidth: 150
                      },
                      {
                        Header: "Alarm Number",
                        accessor: "alarmNumber",
                        style: {"textAlign":"right"},
                        maxWidth: 150
                      },
                      {
                        Header: "Time",
                        accessor: "time",
                        style: {"textAlign":"center"},
                        maxWidth: 150
                      },
                      {
                        Header: "Side Effects Questionnaire also",
                        accessor: "alternativeForm",
                        style: {"textAlign":"center"},
                        maxWidth: 350
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false
                      }
                    ]}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { alarms } = state.alarms;
  return { alarms };
}

const actionCreators = {
  getAll: alarmsActions.getAll
};

const connectedAlarms = connect(mapState, actionCreators)(Alarms);
export { connectedAlarms as Alarms }; 