import React from 'react';
import { ChartsWrapper } from './ChartsWrapper';
import { styled } from 'styled-components';

const Heading = styled.h5`
  margin: 0;
  font-size: 25px;
  text-align: center;
`;

const Description = styled.p`
  margin: 0;
  font-size: 16px;
  text-align: center;
`;

export const NumberChart = ({ title, number = 0, label }) => {

  return (
    <ChartsWrapper title={title}>
      <div>
        <Heading>{number}</Heading>
        <Description>{label}</Description>
      </div>
    </ChartsWrapper>
  )
};