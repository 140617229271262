import { genericConstants } from '../constants';
import { alarmsService} from '../services'; 
import { alertActions } from '.';

export const alarmsActions = {
    getAll
};

function getAll() {
    return dispatch => {
        dispatch(request());

        alarmsService.getAllAlarms()
            .then(
                response => {
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.ALARMS_REQUEST } }
    function success(items) { return { type: genericConstants.ALARMS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.ALARMS_FAILURE, error } }
}