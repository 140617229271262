import * as d3 from 'd3-scale-chromatic';

export const colorUtility ={
    getColorForData
}

const colorRangeInfo = {
    colorStart: 0,
    colorEnd:1,
    useEndAsStart: false,
 }

function calculatePoint(i, intervalSize, colorRangeInfo) {
    var { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
    return (useEndAsStart
      ? (colorEnd - (i * intervalSize))
      : (colorStart + (i * intervalSize)));
  }

  function getColorForData(dataLength) {
    var { colorStart, colorEnd } = colorRangeInfo;
    var colorRange = colorEnd - colorStart;
    var intervalSize = colorRange / dataLength;
    var i, colorPoint;
    var colorArray = [];
  
    for (i = 0; i < dataLength; i++) {
      colorPoint = calculatePoint(i, intervalSize, colorRangeInfo);
      colorArray.push(d3.interpolateTurbo(colorPoint));
    }
  
    return colorArray;
  }  