/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, FormGroup, Form, Input, Button } from "reactstrap";

import Select from "react-select";

import SweetAlert from "react-bootstrap-sweetalert";

import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { participantsActions } from '../../actions';
import { providersService } from '../../services';

class ProvidersEdit extends Component {
    constructor(props) {
        super(props);
        var pid = props.match.params.pid;
        this.state = {
            alert: null,
            show: false,
            id: pid,
            data: [],
            isAdd: false,
            role: "ROLE_PROVIDER",
            validation: {
                username: 'has-danger',
                email: 'has-danger',
                fname: 'has-danger',
                lname: 'has-danger',
                password: 'has-danger',
                passwordVerf: 'has-danger',
            },
            username: '',
            email: '',
            password: '',
            passwordVerf: '',
            title: '',
            fname: '',
            mname: '',
            lname: '',
            phone: '',
            mobile: '',
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            county: '',
            city: '',
            country: '',
            postcode: '',
            jobtitle: ''
        };

        if (props.location.state && props.location.state.theProvider) {
            var p = props.location.state.theProvider;
            if (p && p !== null) {
                this.state.role = p.user.role;
                this.state.username = p.user.login;
                this.state.email = p.user.email;
                this.state.password = "abcdef11";
                this.state.passwordVerf = "abcdef11";
                this.state.title = p.user.title;
                this.state.fname = p.user.firstName;
                this.state.mname = p.user.middleName;
                this.state.lname = p.user.lastName;
                this.state.phone = p.user.phone;
                this.state.mobile = p.user.mobile;
                this.state.address1 = p.user.address.address1 === null ? '' : p.user.address.address1;
                this.state.address2 = p.user.address.address2 === null ? '' : p.user.address.address2;
                this.state.address3 = p.user.address.address3 === null ? '' : p.user.address.address3;
                this.state.address4 = p.user.address.address4 === null ? '' : p.user.address.address4;
                this.state.county = p.user.address.county;
                this.state.city = p.user.address.city;
                this.state.country = p.user.address.country;
                this.state.postcode = p.user.address.postCode;
                this.state.jobtitle = p.jobTitle;
                this.state.validation = {
                    username: '',
                    email: '',
                    fname: '',
                    lname: '',
                    password: '',
                    passwordVerf: '',
                }
            } else {
                this.props.history.goBack();
            }
        } else {
            this.state.isAdd = true;
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        switch (name) {
            case 'username':
                if (value.length >= 5) {
                    this.state.validation["username"] = "has-success";
                } else {
                    this.state.validation["username"] = "has-danger";
                }
                break;
            case 'email':
                var mail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

                if (mail.test(value)) {
                    this.state.validation["email"] = "has-success";
                } else {
                    this.state.validation["email"] = "has-danger";
                }
                break;
            case 'fname':
                if (value.length >= 2) {
                    this.state.validation["fname"] = "has-success";
                } else {
                    this.state.validation["fname"] = "has-danger";
                }
                break;
            case 'lname':
                if (value.length >= 2) {
                    this.state.validation["lname"] = "has-success";
                } else {
                    this.state.validation["lname"] = "has-danger";
                }
                break;
            case 'password':
                var letters = /[a-zA-Z]/;
                var digits = /[0-9]/;
                if (letters.test(value) && digits.test(value) && value.length >= 8) {
                    this.state.validation["password"] = "has-success";
                } else {
                    this.state.validation["password"] = "has-danger";
                }

                if (this.state.passwordVerf === value) {
                    this.state.validation["passwordVerf"] = "has-success";
                } else {
                    this.state.validation["passwordVerf"] = "has-danger";
                }
                break;
            case 'passwordVerf':
                if (this.state.password === value) {
                    this.state.validation["passwordVerf"] = "has-success";
                } else {
                    this.state.validation["passwordVerf"] = "has-danger";
                }
                break;

            default:
                break;
        }
    }

    hideAlert() {
        this.setState({ alert: null });
    }

    hideAlert1() {
        this.setState({ alert: null });
        this.props.history.goBack();
    }

    saveProvider() {
        if (this.state.validation.username === "has-danger" ||
            this.state.validation.email === "has-danger" ||
            this.state.validation.fname === "has-danger" ||
            this.state.validation.lname === "has-danger" ||
            this.state.validation.password === "has-danger" ||
            this.state.validation.passwordVerf === "has-danger") {
            this.setState({
                alert: (
                    <SweetAlert
                        error
                        style={{ display: "block", marginTop: "100px" }}
                        title="Something when wrong!"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="danger"
                    >
                        Please review the errors and try again.
                  </SweetAlert>
                )
            });
        } else {
            var theProvider = {};

            if (this.state.isAdd !== true) {
                theProvider = this.props.location.state.theProvider;
                if (this.state.password !== 'abcdef11') {
                    theProvider.user.password = this.state.password;
                }
                theProvider.user.role = "ROLE_PROVIDER";
            } else {
                theProvider.user = {
                    address: {}
                };

                theProvider.user.password = this.state.password;
            }

            theProvider.user.role = this.state.role;
            theProvider.user.login = this.state.username;
            theProvider.user.email = this.state.email;
            theProvider.user.title = this.state.title;
            theProvider.user.firstName = this.state.fname;
            theProvider.user.middleName = this.state.mname;
            theProvider.user.lastName = this.state.lname;
            theProvider.user.phone = this.state.phone;
            theProvider.user.mobile = this.state.mobile;
            theProvider.user.address.address1 = this.state.address1 === null ? '' : this.state.address1;
            theProvider.user.address.address2 = this.state.address2 === null ? '' : this.state.address2;
            theProvider.user.address.address3 = this.state.address3 === null ? '' : this.state.address3;
            theProvider.user.address.address4 = this.state.address4 === null ? '' : this.state.address4;
            theProvider.user.address.county = this.state.county;
            theProvider.user.address.city = this.state.city;
            theProvider.user.address.country = this.state.country;
            theProvider.user.address.postCode = this.state.postcode;
            theProvider.jobTitle = this.state.jobtitle;

            if (this.state.isAdd !== true) {
                providersService.saveProvider(theProvider, theProvider.id).then(r => {
                    //console.log("ProvidersEidt.saveProvider: ", r);
                    if(r){ 
                        this.setState({
                            alert: (
                                <SweetAlert
                                    success
                                    style={{ display: "block", marginTop: "100px" }}
                                    title="Provider Saved"
                                    onConfirm={() => this.hideAlert1()}
                                    onCancel={() => this.hideAlert1()}
                                    confirmBtnBsStyle="info"
                                >
                                </SweetAlert>
                            )
                        });
                    } else {
                        this.setState({
                            alert: (
                                <SweetAlert
                                    error
                                    style={{ display: "block", marginTop: "100px" }}
                                    title="Something when wrong!"
                                    onConfirm={() => this.hideAlert()}
                                    onCancel={() => this.hideAlert()}
                                    confirmBtnBsStyle="danger"
                                >
                                    There was an error saving the Provider. Check if it doesn't already exist one with the same Username.
                            </SweetAlert>
                            )
                        });
                    }
                }
                );
            } else {
                providersService.addProvider(theProvider).then(r => {
                    //console.log("ProvidersEidt.addProvider: ", r);
                    if(r){ 
                        this.setState({
                            alert: (
                                <SweetAlert
                                    success
                                    style={{ display: "block", marginTop: "100px" }}
                                    title="Provider Saved"
                                    onConfirm={() => this.hideAlert1()}
                                    onCancel={() => this.hideAlert1()}
                                    confirmBtnBsStyle="info"
                                >
                                </SweetAlert>
                            )
                        });
                    } else {
                        this.setState({
                            alert: (
                                <SweetAlert
                                    error
                                    style={{ display: "block", marginTop: "100px" }}
                                    title="Something when wrong!"
                                    onConfirm={() => this.hideAlert()}
                                    onCancel={() => this.hideAlert()}
                                    confirmBtnBsStyle="danger"
                                >
                                    There was an error saving the Provider. Check if it doesn't already exist one with the same Username.
                            </SweetAlert>
                            )
                        });
                    }
                }
                );
            }
        }
    }

    render() {
        //const { items, participantsLoading } = this.props;
        var title = 'Create new provider';
        if (this.state.id > 0) {
            title = `Edit provider ${this.state.username}`
        }

        var titles = [
            { value: "Dr", label: "Dr" },
            { value: "Mr", label: "Mr" },
            { value: "Mrs", label: "Mrs" },
            { value: "Ms", label: "Ms" }
        ];

        var roles = [
            { value: "ROLE_PROVIDER", label: "PROVIDER" },
            { value: "ROLE_RESEARCHER", label: "RESEARCHER" }
        ];


        var selTitle = { value: this.state.title, label: this.state.title };
        var selrole = {
            value: this.state.role,
            label: this.state.role.substring(5, this.state.role.length)
        }

        const usernameValidationError = () => {
            if (this.state.validation.username === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                Username must be unique and at least 5 characters
                        </small>
                        </p>
                    </>);
            }
        }


        const emailValidationError = () => {
            if (this.state.validation.email === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                Please enter a valid email address
                        </small>
                        </p>
                    </>);
            }
        }

        const fnameValidationError = () => {
            if (this.state.validation.fname === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                Please enter a first name.
                        </small>
                        </p>
                    </>);
            }
        }


        const lnameValidationError = () => {
            if (this.state.validation.lname === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                Please enter a last name.
                        </small>
                        </p>
                    </>);
            }
        }

        const paswordValidationError = () => {
            if (this.state.validation.password === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The password should be eight or more characters and contain letters and digits
                        </small>
                        </p>
                    </>);
            }
        }

        const passwordVerifValidationError = () => {
            if (this.state.validation.passwordVerf === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                Both passwords should match
                        </small>
                        </p>
                    </>);
            }
        }

        return (

            <>
                {this.state.alert}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <Card>
                                        <CardTitle tag="h4" className="pull-left" >{title}</CardTitle>
                                        <Button className="btn-fill pull-right" color="success" type="submit"
                                            onClick={this.saveProvider.bind(this)}>
                                            Submit
                                        </Button>
                                        {"  "}
                                        <Button className="btn-fill pull-right" color="danger" type="submit"
                                            onClick={() => {
                                                this.props.history.goBack();
                                            }}>
                                            Cancel
                                        </Button>
                                    </Card>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <CardTitle tag="h5">Provider Type</CardTitle>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select a Type"
                                                        name="type"
                                                        value={selrole}
                                                        options={roles}
                                                        onChange={value =>
                                                            this.setState({ role: value.value })
                                                        } />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <CardTitle tag="h5">User Details</CardTitle>
                                                <label>Username</label>
                                                {usernameValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.username}>
                                                    <Input type="text" name="username"
                                                        value={this.state.username}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                                <label>E-mail</label>
                                                {emailValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.email}>
                                                    <Input type="text" name="email"
                                                        value={this.state.email}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                                <label>Password</label>
                                                {paswordValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.password}>
                                                    <Input type="password" name="password"
                                                        value={this.state.password}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                                <label>Verify Password (repeat)</label>
                                                {passwordVerifValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.passwordVerf}>
                                                    <Input type="password" name="passwordVerf"
                                                        value={this.state.passwordVerf}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <CardTitle tag="h5">Personal Details</CardTitle>
                                                <label>Title</label>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select"
                                                        classNamePrefix="react-select"
                                                        placeholder="Select a title"
                                                        name="title"
                                                        value={selTitle}
                                                        options={titles}
                                                        onChange={value =>
                                                            this.setState({ title: value.value })
                                                        } />
                                                </FormGroup>
                                                <label>First Name</label>
                                                {fnameValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.fname}>
                                                    <Input type="text" name="fname"
                                                        value={this.state.fname}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                                <label>Middle Name</label>
                                                <FormGroup>
                                                    <Input type="text" name="mname"
                                                        value={this.state.mname}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                                <label>Last Name</label>
                                                {lnameValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.lname}>
                                                    <Input type="text" name="lname"
                                                        value={this.state.lname}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <CardTitle tag="h5">Contact Details</CardTitle>
                                                <label>Phone</label>
                                                <FormGroup>
                                                    <Input type="text" name="phone"
                                                        value={this.state.phone}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                                <label>Mobile</label>
                                                <FormGroup>
                                                    <Input type="text" name="mobile"
                                                        value={this.state.mobile}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <CardTitle tag="h5">Address Details</CardTitle>
                                                <label>Street</label>
                                                <FormGroup>
                                                    <Input type="text" name="address1"
                                                        value={this.state.address1}
                                                        onChange={this.handleChange.bind(this)} />
                                                    <br />
                                                    <Input type="text" name="address2"
                                                        value={this.state.address2}
                                                        onChange={this.handleChange.bind(this)} />
                                                    <br />
                                                    <Input type="text" name="address3"
                                                        value={this.state.address3}
                                                        onChange={this.handleChange.bind(this)} />
                                                    <br />
                                                    <Input type="text" name="address4"
                                                        value={this.state.address4}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                                <label>City</label>
                                                <FormGroup>
                                                    <Input type="text" name="city"
                                                        value={this.state.city}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                                <label>County</label>
                                                <FormGroup>
                                                    <Input type="text" name="county"
                                                        value={this.state.county}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                                <label>Country</label>
                                                <FormGroup>
                                                    <Input type="text" name="country"
                                                        value={this.state.country}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                                <label>Postcode</label>
                                                <FormGroup>
                                                    <Input type="text" name="postcode"
                                                        value={this.state.postcode}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <CardTitle tag="h5">Provider Details</CardTitle>
                                                <label>Job Title</label>
                                                <FormGroup>
                                                    <Input type="text" name="jobtitle"
                                                        value={this.state.jobtitle}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>

                                <CardFooter className="pull-right">
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

function mapState(state) {
    const { items, participantsLoading } = state.participants;
    return { items, participantsLoading };
}

const actionCreators = {
    getAll: participantsActions.getAll
};

const connectedProvidersEdit = connect(mapState, actionCreators)(ProvidersEdit);
export { connectedProvidersEdit as ProvidersEdit }; 