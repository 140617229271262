
import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Container,
  Col,
  FormGroup,
  Input,
  FormFeedback,
  Button
} from "reactstrap";

import { checkResetTokenPass, resetPass } from '../../services/data.service';
import SweetAlert from "react-bootstrap-sweetalert";
// core components
import avatar from "assets/img/logo-big.jpg";
import bgImage from "assets/img/bg13.jpg";

class PasswordReset extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username:"",
      token: props.match.params.token,
      alert: null,
      validRequest: false,
      password: "",
      password2: "",
      validation: {
        password: ''
      },
      isValid: false
    }
  }
  componentDidMount() {
    document.body.classList.add("lock-page");
    checkResetTokenPass(this.state.token).then(resp => {
      if (resp.response === "valid") {
        const _newState = {...this.state};
        _newState.username = resp.username;
        _newState.validRequest = true;
        this.setState(_newState);
      } else {
        this.setState({
          alert: (
            <SweetAlert
              warning
              title="Invalid Request!"
              onConfirm={() => this.hideAlert()}
              confirmBtnBsStyle="danger"
            >
              This password request token is not valid. Please make a new request to recover access to our platform.
            </SweetAlert>
          )
        });
      }
    });
  }
  componentWillUnmount() {
    document.body.classList.remove("lock-page");
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  validatePassword() {
    if (this.state.password === this.state.password2) {
      const _newState = {...this.state};
      _newState.validation.password = 'has-success';
      _newState.isValid = true;
      this.setState(_newState);
    }
  }

  duplicatedPassword(_newState) {
    _newState.isValid = false;
    _newState.alert = null;
    _newState.validation.password = "";
    this.setState(_newState);
  }

  resetPassword() {
    const body = {
      username: this.state.username,
      password: this.state.password,
      uuid: this.state.token
    };

    resetPass(body).then(resp => {
      const _newState = {...this.state};
      if (resp.result === "success") {
        _newState.alert = (
          <SweetAlert
            warning
            title="Password Changed!"
            onConfirm={() => this.props.history.push('/auth/login-page')}
            confirmBtnBsStyle="success"
          >
            Your password has been changed. You can now login to our platform.
          </SweetAlert>
        );
      } else if (resp === "duplicatedPassword") {
        _newState.alert = (
          <SweetAlert
              warning
              title="Password Not Changed!"
              onConfirm={() => this.duplicatedPassword(_newState)}
              confirmBtnBsStyle="danger"
            >
              Your password could not be changed. The password has been used before. Please use another password and try again.
            </SweetAlert>
        );
        _newState.isValid = false;
        this.setState({
          password: null,
          password2: null
        });
      } else {
        _newState.alert = (
          <SweetAlert
              warning
              title="Password Not Changed!"
              onConfirm={() => this.hideAlert()}
              confirmBtnBsStyle="danger"
            >
              Password could not be changed, please check that the passwords match and have sufficient complexity
            </SweetAlert>
        );
        _newState.isValid = false;
        _newState.password = "";
        _newState.password2 = "";
      }
      this.setState(_newState);
    });
  }

  render() {
    return (
      <>
        {this.state.alert}
        <div className="content">
          <div className="lock-page">
            {this.state.validRequest && <Container>
              <Col lg={4} md={8} xs={12} className="mr-auto ml-auto">
                <Card className="card-lock text-center">
                  <CardHeader>
                    <img src={avatar} alt="avatar-img" style={{ backgroundColor: 'white', borderRadius: 35 }} />
                  </CardHeader>
                  <CardBody>
                    <CardTitle tag="h3">Password Reset</CardTitle>
                    <br />
                    <FormGroup>
                      <Input type="password" className="placeholder:text-[#767676] placeholder:opacity-100" placeholder="Enter your new password here..."
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value })}/>
                    </FormGroup>
                    <FormGroup className={this.state.validation.password}>
                      <Input type="password" className="placeholder:text-[#767676] placeholder:opacity-100" placeholder="Confirm your new password here..."
                        value={this.state.password2}
                        onChange={(e) => this.setState({ password2: e.target.value }, () => this.validatePassword())}
                        invalid={!this.state.isValid && this.state.password.length > 0}/>
                      <FormFeedback>Make sure your passwords match together</FormFeedback>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" size="lg" className="btn-round" disabled={!this.state.isValid}
                      onClick={this.resetPassword.bind(this)}>
                      Change my Password
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Container>}
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />
      </>
    );
  }
}

export default PasswordReset;
