
import { genericConstants } from '../constants';
 
const initialState = {   
    conceptsLoading:false, 
    error:""
} ;

export function concepts(state = initialState, action) {
    switch (action.type) {
        case genericConstants.CONCEPTS_REQUEST:
            return {
                conceptsLoading: true
            };

        case genericConstants.CONCEPTS_SUCCESS:
            return { 
                conceptsLoading:false,
                concepts: action.items,
                error:""
            };

        case genericConstants.CONCEPTS_FAILURE:
            return {
                conceptsLoading:false,
                concepts: [], 
                error:action.error
            }; 

        default:
            return state
    }
}