/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, FormGroup, Form, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { alarmsActions } from '../../actions';
import { alarmsService } from '../../services';

class AlarmsEdit extends Component {
    constructor(props) {
        super(props);
        var pid = props.match.params.pid;
        this.state = {
            alert: null,
            show: false,
            id: pid,
            data: [],
            isAdd: false,
            validation: {
                day: 'has-danger',
                hour: 'has-danger',
                minute: 'has-danger'
            },
            day: '',
            hour: '',
            minute: '',
            alarmNumber: '',
            alternativeForm: false
        };

        if (props.location.state && props.location.state.theAlarm) {
            var p = props.location.state.theAlarm;
            if (p && p !== null) {
                this.state.day = p.day;
                this.state.hour = p.hour;
                this.state.minute = p.minute;
                this.state.alarmNumber = p.alarmNumber;
                this.state.alternativeForm = p.alternativeForm;
                this.state.validation = {
                    day: '',
                    hour: '',
                    minute: '',
                    alarmNumber: ''
                }
            } else {
                this.props.history.goBack();
            }
        } else {
            this.state.isAdd = true;
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        switch (name) {
            case 'day':
                if (value > 0 && value < 8) {
                    this.state.validation["day"] = "has-success";
                } else {
                    this.state.validation["day"] = "has-danger";
                }
                break;
            case 'hour':
                if (value >= 5 && value < 24) {
                    this.state.validation["hour"] = "has-success";
                } else {
                    this.state.validation["hour"] = "has-danger";
                }
                break;
            case 'minute':
                if (value >= 0 && value < 60) {
                    this.state.validation["minute"] = "has-success";
                } else {
                    this.state.validation["minute"] = "has-danger";
                }
                break;
            case 'alarmNumber':
                if (value > 0 && value < 7) {
                    this.state.validation["alarmNumber"] = "has-success";
                } else {
                    this.state.validation["alarmNumber"] = "has-danger";
                }
                break;
            default:
                break;
        }
    }

    hideAlert() {
        this.setState({ alert: null });
    }

    hideAlert1() {
        this.setState({ alert: null });
        this.props.history.goBack();
    }

    saveAlarm() {
        if (this.state.validation.day === "has-danger" ||
            this.state.validation.alarmNumber === "has-danger" ||
            this.state.validation.hour === "has-danger" ||
            this.state.validation.minute === "has-danger") {
            this.setState({
                alert: (
                    <SweetAlert
                        error
                        style={{ display: "block", marginTop: "100px" }}
                        title="Something when wrong!"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="danger"
                    >
                        Please review the errors and try again.
                  </SweetAlert>
                )
            });
        } else {
            var theAlarm = {};

            if (this.state.isAdd !== true) {
                theAlarm = this.props.location.state.theAlarm;
            } //else { theAlarm.alarmNumber = 1; }

            theAlarm.day = this.state.day;
            theAlarm.hour = this.state.hour;
            theAlarm.minute = this.state.minute;
            theAlarm.alarmNumber = this.state.alarmNumber;
            theAlarm.alternativeForm = this.state.alternativeForm;
        }

        if (this.state.isAdd !== true) {
            alarmsService.saveAlarm(theAlarm, theAlarm.id).then(r => {
                //console.log("AlarmsEdit.saveAlarm: ", r);
                if(r){ 
                    this.setState({
                        alert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "100px" }}
                                title="Alarm Saved"
                                onConfirm={() => this.hideAlert1()}
                                onCancel={() => this.hideAlert1()}
                                confirmBtnBsStyle="info"
                            >
                            </SweetAlert>
                        )
                    });
                } else {
                    this.setState({
                        alert: (
                            <SweetAlert
                                error
                                style={{ display: "block", marginTop: "100px" }}
                                title="Something when wrong!"
                                onConfirm={() => this.hideAlert()}
                                onCancel={() => this.hideAlert()}
                                confirmBtnBsStyle="danger"
                            >
                                There was an error saving the Alarm. Check if it doesn't already exist that alarm number for that day.
                        </SweetAlert>
                        )
                    });
                }
            }
            );
        } else {
            alarmsService.addAlarm(theAlarm).then(r => {
                //console.log("AlarmsEdit.saveAlarm: ", r);
                if(r){ 
                    this.setState({
                        alert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "100px" }}
                                title="Alarm Saved"
                                onConfirm={() => this.hideAlert1()}
                                onCancel={() => this.hideAlert1()}
                                confirmBtnBsStyle="info"
                            >
                            </SweetAlert>
                        )
                    });
                } else {
                    this.setState({
                        alert: (
                            <SweetAlert
                                error
                                style={{ display: "block", marginTop: "100px" }}
                                title="Something when wrong!"
                                onConfirm={() => this.hideAlert()}
                                onCancel={() => this.hideAlert()}
                                confirmBtnBsStyle="danger"
                            >
                                There was an error saving the Alarm. Check if it doesn't already exist that alarm number for that day.
                        </SweetAlert>
                        )
                    });
                }
            });
        }
    }

    render() {
        //const { items, participantsLoading } = this.props;
        var title = 'Create new alarm';
        if (this.state.id > 0) {
            title = `Edit alarm ${this.state.id}`
        }

        const dayValidationError = () => {
            if (this.state.validation.day === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                Day must be a number between 1 and 7
                        </small>
                        </p>
                    </>);
            }
        }

        const alarmNumberValidationError = () => {
            if (this.state.validation.alarmNumber === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                Alarm Number must be a number between 1 and 6
                        </small>
                        </p>
                    </>);
            }
        }

        const hourValidationError = () => {
            if (this.state.validation.hour === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The alarms can only be set from 5:00 to 23:59
                        </small>
                        </p>
                    </>);
            }
        }

        const minuteValidationError = () => {
            if (this.state.validation.minute === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                            The alarms can only be set from 5:00 to 23:59
                        </small>
                        </p>
                    </>);
            }
        }

        return (

            <>
                {this.state.alert}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <Card>
                                        <CardTitle tag="h4" className="pull-left" >{title}</CardTitle>
                                        <Button className="btn-fill pull-right" color="success" type="submit"
                                            onClick={this.saveAlarm.bind(this)}>
                                            Submit
                                        </Button>
                                        {"  "}
                                        <Button className="btn-fill pull-right" color="danger" type="submit"
                                            onClick={() => {
                                                this.props.history.goBack();
                                            }}>
                                            Cancel
                                        </Button>
                                    </Card>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <CardTitle tag="h5">Day</CardTitle>
                                                {dayValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.day}>
                                                    <Input type="number" name="day"
                                                        value={this.state.day}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <CardTitle tag="h5">Alarm Number</CardTitle>
                                                {alarmNumberValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.alarmNumber}>
                                                    <Input type="number" name="alarmNumber"
                                                        value={this.state.alarmNumber}
                                                        onChange={this.handleChange.bind(this)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <br/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <CardTitle tag="h5">Time</CardTitle>
                                                {hourValidationError()}
                                                {minuteValidationError()}
                                                <FormGroup className={"has-label " + this.state.validation.hour}></FormGroup>
                                                <FormGroup className={"has-label " + this.state.validation.minute}></FormGroup>
                                                <Row>
                                                    <Col xs={4} md={4}>
                                                        <Input type="number" name="hour"
                                                            value={this.state.hour}
                                                            onChange={this.handleChange.bind(this)} />
                                                    </Col>:
                                                    <Col xs={4} md={4}>
                                                        <Input type="number" name="minute"
                                                            value={this.state.minute}
                                                            onChange={this.handleChange.bind(this)} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <FormGroup check className="mt-3">
                                                    <Label check>
                                                        <Input type="checkbox" name="alternativeForm"
                                                            checked={this.state.alternativeForm}
                                                            onChange={() => this.setState({ alternativeForm: !this.state.alternativeForm })} />
                                                        <span className="form-check-sign" /> 
                                                        Side Effects Questionnaire also
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>

                                <CardFooter className="pull-right">
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

function mapState(state) {
    const { items, alarmsLoading } = state.alarms;
    return { items, alarmsLoading };
}

const actionCreators = {
    getAll: alarmsActions.getAll
};

const connectedAlarmsEdit = connect(mapState, actionCreators)(AlarmsEdit);
export { connectedAlarmsEdit as AlarmsEdit }; 