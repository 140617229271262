import { genericConstants } from '../constants';
import { settingsService} from '../services'; 
import { alertActions } from '.';

export const settingsActions = {
    getAll
};

function getAll() {
    return dispatch => {
        dispatch(request());

        settingsService.getAllSettings()
            .then(
                response => {
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.SETTINGS_REQUEST } }
    function success(items) { return { type: genericConstants.SETTINGS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.SETTINGS_FAILURE, error } }
}