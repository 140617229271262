import React, { createContext, useContext } from 'react';
import { featureFlags } from '../constants/feature.constants';

const FeatureFlagContext = createContext(featureFlags);

export const useFeature = (flag) => {
  const flags = useContext(FeatureFlagContext);
  return flags[flag];
};

export const FeatureFlagProvider = ({ children }) => {
  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
