import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useParams, withRouter } from 'react-router-dom/cjs/react-router-dom';
import moment from 'moment';
import { Badge, NavLink, Spinner } from 'reactstrap';
import Tippy from '@tippyjs/react';

import { participantsActions, alertActions } from '../../../actions';
import { QuestionnaireConcepts } from '../constants/questionnaire.constants';

const scoreCellWidth = '65px';

const conditionalBreachCellStyle = {
  backgroundColor: 'rgba(255, 0, 0, 0.3)',
};

const conditionalScoreCellPredicate = (row, key, baseline) => {
  if (!baseline || !key || !row) return false;

  const twentyEightDaysSinceBaseline = moment(row.datetime).diff(moment(baseline.date), 'days') > 28;

  if (!twentyEightDaysSinceBaseline) return false;

  const concept = QuestionnaireConcepts[key];
  const rowValue = calculateScore(row, concept.name);

  const isOutsideBoundary = (rowValue, meanValue, deviationValue) => {
    const lowerBound = meanValue - deviationValue;
    const upperBound = meanValue + deviationValue;

    return rowValue < lowerBound || rowValue > upperBound;
  };

  const isBaselineKeySet = baseline[key] !== undefined;
  if (!isBaselineKeySet) return false;

  return isOutsideBoundary(rowValue, baseline[key].meanValue, baseline[key].standardDeviation);
};

const calculateScore = (row, key) => {
  const {
    observations
  } = row;

  const filteredObservations = observations.filter(
    ({ question: { concept: { description } } }) => description === key
  );

  const total = filteredObservations.reduce(
    (sum, { valueNumeric = 0 }) => sum + valueNumeric, 0
  );

  const count = filteredObservations.length;

  return count > 0 ? Number((total / count).toFixed(1)) : 0;
};

const calculateTotalScore = (row) => {
  const {
    observations
  } = row;

  const descriptions = [...observations.reduce((acc, { question: { concept: { description } } }) => {
    if (!acc.includes(description)) acc.push(description);
    return acc;
  }, [])];

  const meanAverages = descriptions.map(description =>
    calculateScore({ observations }, description)
  );

  const totalMeanAverage = meanAverages.reduce((sum, mean) => sum + mean, 0) / meanAverages.length;

  return meanAverages.length > 0 ? totalMeanAverage.toFixed(1) : "0.0";
};

const calculateScoreTotal = (baselineData) => {
  const concepts = Object.keys(QuestionnaireConcepts);

  const totalMean = concepts.reduce((sum, conceptKey) => {
    return sum + (baselineData[QuestionnaireConcepts[conceptKey].value]?.meanValue || 0);
  }, 0);

  const meanValue = totalMean / concepts.length;

  return parseFloat(meanValue.toFixed(1));
};

const shouldOmitColumn = (key, hasPersonalisedDelusions, hasPersonalisedQuestions) => {
  if (key === 'delusions' && !hasPersonalisedDelusions) return true;
  if (key === 'personalised' && !hasPersonalisedQuestions) return true;

  return false;
}

const getLatestBaselineDate = (baselineCalculations) => {
  if (!baselineCalculations || baselineCalculations.length === 0) {
    return null;
  }
  const latestCalculation = baselineCalculations.reduce((latest, current) => {
    const currentDate = moment(current.baselineCalculationDate);
    return currentDate.isAfter(latest) ? currentDate : latest;
  }, moment(baselineCalculations[0].baselineCalculationDate));

  return latestCalculation.toISOString();
};

export const QuestionnaireList = withRouter(({ history }) => {
  const { pid: participantId } = useParams();

  const dispatch = useDispatch();

  const [hasPersonalisedDelusions, setHasPersonalisedDelusions] = useState(false);
  const [hasPersonalisedQuestions, setHasPersonalisedQuestions] = useState(false);

  const {
    participantsLoading: storeLoading,
    summary: {
      encounters: storedEncounters = [],
    }
  } = useSelector((state) => state.participants);

  const {
    alerts: storedAlerts,
    alertsLoading,
  } = useSelector(state => state.alerts);

  const [baseline, setBaseline] = useState(null);
  const [encounters, setEncounters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(alertActions.getParticipantAlerts(participantId));
    dispatch(participantsActions.getSummary(participantId));
  }, [dispatch, participantId]);

  const getConceptBaseline = useCallback(
    (alertConfig, observations, concept) => {

      const emptyBaseline = {
        meanValue: 0,
        deviationValue: 100,
        concept,
        conceptId: ''
      };

      if (!alertConfig || !alertConfig.baselineCalculations.length) {
        return emptyBaseline;
      }

      const conceptId = observations.find(
        ({ question }) => question?.concept?.description === concept
      )?.question?.concept?.id;

      const mostRecentBaselineCalculation = alertConfig.baselineCalculations.reduce((latest, current) => {
        return moment(current.baselineCalculationDate).isAfter(moment(latest.baselineCalculationDate)) ? current : latest;
      });

      const baseline = mostRecentBaselineCalculation.baselineScores.find(
        ({ conceptId: id }) => id === conceptId
      );

      if (!baseline) {
        return emptyBaseline;
      }

      return {
        ...baseline,
        meanValue: parseFloat(baseline.meanValue.toFixed(1)),
        concept
      };
    },
    []
  );

  const hasEws = (date) => {
    if (alertsLoading || !storedAlerts.length) return false;

    return storedAlerts.some(alert =>
      moment(alert.alertDate).isSame(moment(date), 'day')
    );
  };

  const calculateBaselineData = useCallback(
    ({ observations, participant: { alertConfig } }) => {
      const baselineCalculationDate = getLatestBaselineDate(alertConfig.baselineCalculations);
      const hasBaseline = Boolean(baselineCalculationDate) && alertConfig.baselineCalculations.length > 0;

      const baselineData = {
        name: 'BASELINE',
        status: hasBaseline ? 'SET' : 'NOT SET',
        score: 0,
        precipitants: getConceptBaseline(alertConfig, observations, 'Precipitants'),
        hope: getConceptBaseline(alertConfig, observations, 'Hope'),
        mood: getConceptBaseline(alertConfig, observations, 'Mood'),
        anxiety: getConceptBaseline(alertConfig, observations, 'Anxiety'),
        self: getConceptBaseline(alertConfig, observations, 'Self'),
        others: getConceptBaseline(alertConfig, observations, 'Others'),
        coping: getConceptBaseline(alertConfig, observations, 'Coping'),
        fearOfRecurrence: getConceptBaseline(alertConfig, observations, 'Fear of recurrence'),
        unusualVoices: getConceptBaseline(alertConfig, observations, 'Unusual voices'),
        seeingThings: getConceptBaseline(alertConfig, observations, 'Seeing things'),
        activityAndActivation: getConceptBaseline(alertConfig, observations, 'Activity and Activation'),
        feelingThreatened: getConceptBaseline(alertConfig, observations, 'Feeling threatened'),
        date: baselineCalculationDate,
      };

      if (hasPersonalisedDelusions) {
        baselineData.delusions = getConceptBaseline(alertConfig, observations, 'Delusions');
      }

      if (hasPersonalisedQuestions) {
        baselineData.personalised = getConceptBaseline(alertConfig, observations, 'Personalised');
      }

      baselineData.score = calculateScoreTotal(baselineData);
      setBaseline(baselineData);
    },
    [getConceptBaseline, setBaseline, hasPersonalisedDelusions, hasPersonalisedQuestions] //, encounters
  );

  useEffect(() => {
    if (!storeLoading && storedEncounters.length === 0) {
      setIsLoading(false);
    }

    if (storedEncounters) {
      setEncounters(storedEncounters);
    }
  }, [storedEncounters, storeLoading]);

  useEffect(() => {
    const hasPersonalisedDelusions = encounters.some(({ observations }) => {
      return observations.some(({ question }) => {
        return question?.concept?.description === QuestionnaireConcepts.delusions.name;
      });
    });

    const hasPersonalisedQuestions = encounters.some(({ observations }) => {
      return observations.some(({ question }) => {
        return question?.concept?.description === QuestionnaireConcepts.personalised.name;
      });
    });

    setHasPersonalisedDelusions(hasPersonalisedDelusions);
    setHasPersonalisedQuestions(hasPersonalisedQuestions);

    if (encounters.length > 0) {
      calculateBaselineData(encounters[0]);
      setIsLoading(false);
    }
  }, [encounters, calculateBaselineData]);

  const EwsBadge = ({ row }) => {
    const navigateToAlert = () => {
      const {
        datetime,
      } = row;

      const alert = storedAlerts.find(alert =>
        moment(alert.alertDate).isSame(moment(datetime), 'day')
      );

      return history.push(`../${participantId}/alerts/${alert.id}/edit`);
    }

    return (
      <Badge color="danger" onClick={navigateToAlert} className="cursor-pointer">EWS</Badge>
    )
  };

  const baselineColumns = [
    {
      name: <p>Questionnaire</p>,
      selector: row => "BASELINE",
      width: '150px',
    },
    {
      name: <p>Status</p>,
      selector: row => row.status,
      width: '150px',
    },
    {
      name: <p>Total</p>,
      selector: row => `${row.score}/7.0`,
      width: '80px',
    },
    ...Object.keys(QuestionnaireConcepts).map(key => {
      const concept = QuestionnaireConcepts[key];

      return {
        name: <Tippy content={concept.name} theme="light"><p>{concept.code}</p></Tippy>,
        selector: row => row[concept.value]?.meanValue || 0,
        width: scoreCellWidth,
        omit: shouldOmitColumn(key, hasPersonalisedDelusions, hasPersonalisedQuestions),
      };
    }),
    {
      name: <p>Date</p>,
      selector: row => moment(row.date).isValid() ? moment(row.date).format('DD/MM/YYYY') : '--',
    },
    {
      name: '',
      $right: 'true',
    },
  ]

  const columns = [
    {
      name: <p>Questionnaire</p>,
      selector: row => (
        <>
          <p>EMPOWER</p>
          {hasEws(row.datetime) && <EwsBadge row={row}/>} 
        </>
      ),
      sortable: 'true',
      width: '150px',
    },
    {
      name: <p>Status</p>,
      selector: row => row.status,
      sortable: 'true',
      width: '150px',
    },
    {
      name: <p>Total</p>,
      selector: row => `${calculateTotalScore(row)}/7.0`,
      sortable: 'true',
      width: '80px',
    },
    ...Object.keys(QuestionnaireConcepts).map(key => {
      const concept = QuestionnaireConcepts[key];

      return {
        name: <Tippy content={concept.name} theme="light"><p>{concept.code}</p></Tippy>,
        selector: row => calculateScore(row, concept.name),
        sortable: 'true',
        width: scoreCellWidth,
        conditionalCellStyles: [
          {
            when: row => conditionalScoreCellPredicate(row, concept.value, baseline),
            style: conditionalBreachCellStyle
          },
        ],
        omit: shouldOmitColumn(key, hasPersonalisedDelusions, hasPersonalisedQuestions),
      };
    }),
    {
      name: <p>Date</p>,
      selector: row => moment(row.datetime).format('DD/MM/YYYY'),
      sortable: 'true',
      sortFunction: (a, b) => moment(a.datetime).unix() - moment(b.datetime).unix(),
    },
    {
      name: <p>Actions</p>,
      cell: row => (
        <>
          <NavLink href={`/clintouch/admin/participants/${participantId}/encounters/${row.id}`}>View</NavLink>
        </>
      ),
      $right: 'true',
    },
  ]

  return (
    <>
      <DataTable
        columns={baselineColumns}
        data={baseline ? [baseline] : []}
        progressPending={isLoading}
      />
      <DataTable
        columns={columns}
        data={encounters}
        pagination
        highlightOnHover
        progressPending={isLoading}
        progressComponent={<Spinner />}
        noDataComponent={<h2>No data found</h2>}
      />
    </>
  );
});
