import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: white;
  border: 1px solid #e6e6e6;
  margin-bottom: 30px;
`;

const Header = styled.div`
  background-color: #06848f;
  color: white;
  text-align: center;
  border-radius: 10px 10px 0 0;
  padding: 15px;
  border-bottom: 2px solid #024147;

  h5 {
    margin: 0;
    font-size: 16px;
  }
`;

const Content = styled.div`
  padding: 20px;
`;

export const ChartsWrapper = ({ children, title }) => {
  return (
    <Wrapper>
      <Header>
        <h5>{title}</h5>
      </Header>
      <Content>
        {children}
      </Content>
    </Wrapper>
  );
};
